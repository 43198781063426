import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { AppRoutingModule } from './app-routing.module';
import { ExamplePageComponent } from './example-page/example-page.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { AuthGuardService } from './oauth-callback/auth-guard.service';
import { OauthTokenService } from './oauth-callback/oauth-token.service';
import { ServerDetails } from './oauth-callback/SeverDetails';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MatTabsModule } from '@angular/material/tabs';
import { DealerModule } from './dealer/dealer.module';
import { FileUploadModule } from 'primeng/fileupload';
import { NavigationService } from './services/navigation.service';
import { SupplierModule } from './supplier/supplier.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { CommonLandingComponent } from './common-landing/common-landing.component';
import { CommonIndexComponent } from './common-index/common-index.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './dealer/services/http-request-interceptor';
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ExamplePageComponent,
    CommonLayoutComponent,
    CommonLandingComponent,
    CommonIndexComponent
  ],
  imports: [
    MessagesModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    CalendarModule,
    MatSidenavModule,
    MatExpansionModule,
    SelectButtonModule,
    CardModule,
    InputSwitchModule,
    TabViewModule,
    DropdownModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ButtonModule,
    MenubarModule,
    PanelMenuModule,
    SidebarModule,
    ToastModule,
    PanelModule,
    InputTextModule,
    FormsModule,
    CheckboxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    DropdownModule,
    ConfirmDialogModule,
    DialogModule,
    ReactiveFormsModule,
    InputSwitchModule,
    TableModule,
    HttpClientModule,
    InputMaskModule,
    InputNumberModule,
    MatTabsModule,
    MessageModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    TooltipModule,
    DealerModule,
    MatMenuModule,
    FileUploadModule,
    SupplierModule,
    DynamicDialogModule,
		ToastModule,
    ScrollPanelModule,
	  NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    MessageService,
    AuthGuardService,
    OauthTokenService,
    ServerDetails,
    NavigationService,
    InAppBrowser
  
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
