
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { OrderFilter } from '../dealer-order-inquiry/orderfilter';
/**
 *
 * Admin
 * @export
 * @class AdminService
 */
@Injectable()
export class NavigationService {
  // orderFilter: OrderFilter = new OrderFilter();
  public currentUrl = '';
  public isBackActive=false;
  superSessionFilter: any;
  backOrderFilter: any;
  partSearchFilter: any;
  constructor(public http: HttpClient) {
  }
  fetchUrl() {
    console.log('currentUrl'+this.currentUrl);
    return this.currentUrl;
  }
  setUrl(url){
    console.log('routerurl'+url);
    this.currentUrl=url;
  }
}
