import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PandaTrialService {

    token: string;

    constructor(private http: HttpClient) {
        this.token = 'Bearer ' + sessionStorage['gateway_access_token']
    }

    getPartNumberPanelDetails(value: string, marketId: string): Observable<any> {
        return this.http.get(environment.baseUrl + "v1/m15Values/getpartsvalue/" + value, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
    }

    getItemDetailsValue(vendorCode: string, value: number, marketId: string): Observable<any> {
        return this.http.get(environment.baseUrl + "v1/m15Values/details/" + vendorCode + "/" + value, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
    }

}