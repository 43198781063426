<div style="min-height: 88vh; ">
  <form [formGroup]="dealerBackorderRequestForm" style="padding: 0%;">
    <mat-grid-tile style="background-image: url('../../assets/image/mache1.jpg');">
      <mat-card class="basecard ">
        <div class="flex pb-2 mb-2  HeadTitle ">
          <div class="mr-2 ml-2">
            <mat-icon class="backIcon" (click)="navToPartSearch()" *ngIf="backActive" style="cursor: pointer;"
              id="btnBack">keyboard_arrow_left</mat-icon>
            <mat-icon class="titleIcon" *ngIf="showIcon"> post_add</mat-icon>
          </div>

          <!-- <div class="p-mr-2  orderHead">
            <span>
              BACKORDER STATUS REQUEST
            </span>
            <div class="flex-grow-1"></div> 
                    <div class="p-mr-2 orderHead" >
                        <span>{{ this.utcDate }}</span>
                    </div>
          </div> -->

          <div class="mr-2 orderHead">
            <span>
              BACKORDER STATUS REQUEST
            </span>
        </div>
        <div class="flex-grow-1"></div> <!-- This will push the following span to the right -->
        <div class="mr-2 orderHead" >
            <span>{{ this.utcDate }}</span>
        </div>
        </div>
        <mat-card-content>
          <div class="formgrid p-grid web shadow-6">
            <div class="formgrid p-grid ml-2">
              <div class="field col-6 col-md-3 divbody mt-2 mr-4">
                <label for="partNumber" id="lblPartNumber" class="formLabels">Part Number</label>
                <input class="form-control m-input" id="partNumber" type="text" (keydown.enter)="submit()"
                  (keyup)="formatPartNumber()" formControlName="partNumber" pInputText>
                <small id="username2-help" class="errorMessage" *ngIf="showErrorMsg && submitted">Please enter Part
                  Number</small>
                  

              </div>


              <div class="field col-6 col-md-3 divbody mt-2">
                <label for="partNumber" id="lblAdviceCode" class="formLabels ">Advice Code</label>
                <p-dropdown [options]="adviseCodes" formControlName="adviceCode" name="type" id="adviceCode"
                (keydown.enter)="submit()" placeholder="Select Advice Code" optionLabel="code" optionValue="name">
                </p-dropdown>
              </div>

               <div class="field col-6 col-md-3 divbody mt-2">
                <label for="partNumber" id="lblAdviceCode" class="formLabels ">Order Type</label>
                <p-dropdown [options]="orderType" formControlName="type" name="type" id="orderType"
                (keydown.enter)="submit()" placeholder="Select Order Type" optionLabel="code" optionValue="name">
                </p-dropdown>
              
              </div>  
            </div>

            <div class="flex justify-content-center">
              <div class="mr-2"> <button pButton id="btnSearch" type="button" label="Search" icon="pi pi-search"
                  class="btn-Search mb-2 mr-2 " (click)="submit()" (keydown.enter)="submit()"
                  (keyup.enter)="submit()"></button></div>
              <div class="mr-2"><button pButton id="btnClear" type="button" label="Clear" icon="pi pi-times"
                  class="btn-clear  mb-2" (click)="clear()"></button></div>

            </div>
          </div>
          <div class="webTable  shadow-6" style="margin-top: 5px;" *ngIf="backOrderList.length>0">
            <p-table #dt1 [columns]="cols" [value]="backOrderList" class="ml-2 mb-2"
              [globalFilterFields]="['partNumber']" styleClass="p-datatable-responsive-demo" [lazy]="true"
              (onRowSelect)="onRowSelect($event)" [loading]="loading" (onLazyLoad)="loadOrderDetail($event)"
              [paginator]="true" [rows]="10" id="webTable" [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [totalRecords]="totalRecords">
              <ng-template pTemplate="caption">
                <div class="p-grid">
                  <div class="col-5" style="width: 39%; margin-top: 0;">
                    <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL"
                      id="btnExportExcel" (click)="getExcelData()"
                      style="margin-right: 0.5em;background-color: #2e5385;"></button>
                      <span class="progress-spinner" *ngIf="isSpinner==1">
                        <p-progressSpinner [style]="{width: '25px', height: '25px'}" 
                        styleClass="custom-spinner" strokeWidth="8" 
                        fill="var(--surface-ground)" animationDuration=".5s">
                      </p-progressSpinner>
                         
                      </span>
                  </div>
                  
  
                  <div class="col-2">
                  </div>
                </div>


              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 200px;">Part Number</th>
                  <th class="ordertypeHeader" id="typeSort" style="width: 95px;" pSortableColumn="type">Type
                    <p-sortIcon field="type">
                    </p-sortIcon>
                  </th>
                  <th style="width: 120px;" class="orderNumberHeader" id="orderNumberSort"
                    pSortableColumn="orderNumber">Order No<p-sortIcon field="orderNo">
                    </p-sortIcon>
                  </th>
                  <th style="width: 137px;" class="registerNumberHeader" id="registerNoSort"
                    pSortableColumn="registerNumber">Register No<p-sortIcon field="registerNo">
                    </p-sortIcon>
                  </th>
                  <th style="width: 90px;" class="itemHeader" id="itemSort" pSortableColumn="item">Item<p-sortIcon
                      field="item">
                    </p-sortIcon>
                  </th>

                  <th style="width: 110px;" class="adviceCodeHeader" id="advicecodeSort" pSortableColumn="adviceCode">
                    Advice<p-sortIcon field="adviceCode">
                    </p-sortIcon>
                  </th>
                  <th style="width: 83px;" class="quantityOrderHeader" id="quantityOrderSort"
                    pSortableColumn="quantityOrder">Qty<p-sortIcon field="quantityOrder">
                    </p-sortIcon>
                  </th>
                  <th class="availableDateHeader">Available

                  </th>
                </tr>
                  <tr>
                  <th>

                  </th>
                  <th class="filterBox">
                    
                       <!-- <span class="p-input-icon-right">
                        <i   (click)="clearTypeFilter();sendFilterVal($event)">x</i>
                        <input type="text" pInputText class="filterStyle"
                        formControlName="typeFilter"
                        (keyup)="formattypeFilter()"
                       (keydown.enter)="sendFilterVal($event)">
                    </span> -->
                    <span class="p-input-icon-right">
                      <i class="pi pi-times-circle" *ngIf="enableCleartypeFilter" (click)="clearTypeFilter() ;sendFilterVal($event)"></i>
                      <input type="text" pInputText formControlName="typeFilter" class="filtertext"
                      (keyup)="formattypeFilter()"
                     (keydown.enter)="sendFilterVal($event)" >
                  </span>
                  </th>
                  <th class="filterBox">
                     
                      <span class="p-input-icon-right">
                        <i class="pi pi-times-circle" *ngIf="enableClearordeNoFilter" (click)="clearOrderFilter() ;sendFilterVal($event)"></i>
                        <input type="text" pInputText 
                        formControlName="ordeNoFilter"
                        (keyup)="formatOrderFilter()"
                       (keydown.enter)="sendFilterVal($event)">
                    </span>
                      
                  </th>
                  <th class="filterBox">
                    
                      <span class="p-input-icon-right">
                        <i class="pi pi-times-circle" *ngIf="enableClearregisterNumberFiter" (click)="clearRegisterFilter();sendFilterVal($event)"></i>
                        <input type="text" pInputText 
                        formControlName="registerNumberFiter"
                        (keyup)="formatregisterNumberFilter()"
                       (keydown.enter)="sendFilterVal($event)">
                    </span>
                  </th>
                  <th class="filterBox">
                    
                      <span class="p-input-icon-right ">
                        <i class="pi pi-times-circle" *ngIf="enableClearitemFilter" (click)="clearItemFilter();sendFilterVal($event)"></i>
                        <input type="text" pInputText 
                        formControlName="itemFilter"
                        (keyup)="formatitemFilter()"
                       (keydown.enter)="sendFilterVal($event)">
                    </span>
                      
                  </th>

                  <th class="filterBox">
                    
                   
                       <span class="p-input-icon-right">
                        <i class="pi pi-times-circle" *ngIf="enableClearadviceCodeFilter"(click)="clearadvFilter();sendFilterVal($event)"></i>
                        <input type="text" pInputText 
                        formControlName="adviceCodeFilter"
                        (keyup)="formatadviceCodeFilter()"
                       (keydown.enter)="sendFilterVal($event)">
                    </span>
                  </th>
                  <th class="filterBox">
                   
                     <span class="p-input-icon-right">
                      <i class="pi pi-times-circle" *ngIf="enableClearQty" (click)="clearQtyFilter();sendFilterVal($event)"></i>
                      <input type="text" pInputText 
                      formControlName="quantityOrderFilter" 
                      (keyup)="formatquantityOrderFilter()"
                     (keydown.enter)="sendFilterVal($event)">
                  </span>
                  </th>
                  <th>
                   <!-- <p-calendar [(ngModel)]="rangeDates" selectionMode="range" (keydown.enter)="$event.preventDefault()" [readonlyInput]="false" inputId="range" 
                      appendTo="body"></p-calendar>  -->
                   </th>
                </tr>   
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="font-size: 15px;">
                  <td style="text-align: left;">

                    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" id="btncontectMenu"
                      aria-label="Example icon-button with a menu" (keydown.enter)="$event.preventDefault()">
                      <mat-icon style="font-size: 20px; cursor: pointer;" (keydown.enter)="$event.preventDefault()">
                        more_vert
                      </mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="navToSupersession(rowData)" id="btnnavToSupersession">
                        <mat-icon>add_task</mat-icon>
                        <span>Supersession Inquiry</span>
                      </button> 

                    </mat-menu>-->
                <p-menu #menu [popup]="true" appendTo="body" [model]="items" >
                  
                </p-menu>
                    
                    <mat-icon  style="font-size: 16px; cursor: pointer;margin-top: 2%;"
                    (click)="menu.toggle($event);selectedrow(rowData)">more_vert
                                            </mat-icon>
                  
                    

                    <a (click)="navToPartInq(rowData)" id="navToPartInq"
                      style="cursor: pointer;text-align: center;color: blue;text-decoration: underline;">
                      {{rowData.partNo}}
                    </a>
                  </td>
                  <td style="text-align: center;">
                    {{rowData.type}}
                  </td>
                  <td style="text-align: center;">
                    {{rowData.orderNo}}
                  </td>
                  <td style="text-align: center;">
                    {{rowData.registerNo}}
                  </td>
                  <td style="text-align: center;">
                    {{rowData.item}}
                  </td>

                  <td style="text-align: center;">
                    {{rowData.adviceCode}}
                  </td>
                  <td style="text-align: center;">
                    {{rowData.quantityOrder}}
                  </td>
                  <td style="text-align: center;">
                    {{rowData.availableDate}}
                  </td>
                  <!-- <td style="text-align: center;">

                    <span style="float: right" *ngIf="rowData.adviceCode && rowData.adviceCode!=''"
                      class="tag">{{rowData.adviceCode}}</span> -->
                    <!-- <span style="float: right" *ngIf="rowData.partNumber == '' " class="tag">UNKN</span> -->

                  <!-- </td> -->

                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="formgrid p-grid mob " *ngIf="showFilter || backOrderList.length==0">
            <mat-accordion>

              <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header style="height: 35px !important;">
                  <mat-panel-title> Filter Options </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="field col-12 col-md-4 divbody ">

                  <input class="form-control m-input" id="searchBy" type="text" placeholder="Search by Part Number"
                    formControlName="partNumber" id="partNumber" (keyup)="formatPartNumber()" pInputText>

                  <small id="username2-help" class="errorMessage" *ngIf="showErrorMsg && submitted">Please enter Part
                    Number</small>

                </div>

                <div class="field col-12 col-md-4 divbody ">
                  <select required class="form-control m-input" name="type" id="orderType" formControlName="type">
                    <option value="" disabled selected>Search by Order Type</option>
                    <option value="1">1E</option>
                    <option value="2">2E</option>
                    <option value="3">3E</option>
                  </select>
                </div>
                <div class="field col-12 col-md-4 divbody mt-2">

                  <select required class="form-control m-input" name="type" id="adviceCode" (keydown.enter)="submit()"
                    formControlName="adviceCode">
                    <option value="" disabled selected>Search by Advice Code</option>
                    <option value="5">CAN</option>
                    <option value="10">B/O</option>
                    <option value="12">DEF</option>
                  </select>


                </div>





                <div class="flex justify-content-center">
                  <div class="mr-2"> <button pButton id="btnSearch" type="button" label="Search" icon="pi pi-search"
                      class="btn-Search  mb-2 mr-2 " (click)="submit()" (keydown.enter)="searchDealer()"
                      (keyup.enter)="searchDealer()"></button></div>
                  <div class="mr-2"><button pButton id="btnClear" type="button" label="Clear" icon="pi pi-times"
                      class="btn-clear  mb-2" (click)="clear()"></button></div>

                </div>
              </mat-expansion-panel>
            </mat-accordion>


          </div>
          <div class="ml-2 mt-2">


            <small id="username2-help" class="errorMessage" *ngIf="!showErrorMsg && showError  &&
            backOrderList.length==0 ">No records found. Please check the search criteria</small>
          </div>
          <p-toast></p-toast>
          <p-toast position="top-left" key="c" (onClose)="onReject()" [baseZIndex]="5000">
            <ng-template let-message pTemplate="message">
                <div class="flex flex-column" style="flex: 1">
                    <div class="text-center">
                        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                        <h4>{{message.summary}}</h4>
                        <p>{{message.detail}}</p>
                    </div>
                    <div class="p-grid">
                        <div class="col-6">
                            <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
                        </div>
                        <div class="col-6">
                            <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-toast>
          <div class=" tablestyle mobTable">


            <div class="flex mt-3 mb-2 flex justify-content-between" *ngIf="backOrderList.length>0 && showmobFilter;">


              <div *ngIf="backOrderList.length>0 && showmobFilter" class="mt-2">


                <span class="selectedFilter" *ngIf="filteredAdviceCode!=''">{{filteredAdviceCode}}</span>
                <span class="selectedFilter" *ngIf="filteredType!=''">{{filteredType}}E</span>

              </div>
              <button pButton label="Filter" class="filterIcon" icon="pi pi-filter" (click)="showFilterOpt()"></button>
            </div>

            <div *ngIf="backOrderList.length>0 && showmobFilter" class="mt-2 ml-2">
              <!-- <div class="p-mr-2"   style="font-size: 15px;font-weight: 550;">Part Number: {{fecthedPart}}</div> -->
              <div class="flex justify-content-between mt-2 mb-2 mr-2">
                <span style="font-size: 16px;font-weight: 550;">Order Number</span>
                <span style="font-size: 16px;font-weight: 550;">Register Number</span>
              </div>
            </div>


            <p-table #dt1 [columns]="cols" [value]="backOrderList" styleClass="p-datatable-responsive-demo"
              [lazy]="true" id="mobTable" (onLazyLoad)="loadOrderDetail($event)" [showCurrentPageReport]="true"
              [paginator]="showPage" [rows]="10" currentPageReportTemplate="{first} to {last} of {totalRecords}"
              [totalRecords]="totalRecords">

              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th class="title" pSortableColumn="type">Type <p-sortIcon field="type"></p-sortIcon>
                  </th>
                  <th pSortableColumn="orderNo">Order No<p-sortIcon field="orderNo">
                    </p-sortIcon>
                  </th>
                  <th pSortableColumn="registerNo">Register No<p-sortIcon field="registerNo">
                    </p-sortIcon>
                  </th>
                  <th pSortableColumn="item">Item<p-sortIcon field="item">
                    </p-sortIcon>
                  </th>
                  <th pSortableColumn="status">Status<p-sortIcon field="status">
                    </p-sortIcon>
                  </th>
                  <th pSortableColumn="qtyOrd">Qty Ord<p-sortIcon field="qtyOrd">
                    </p-sortIcon>
                  </th>

                  <th pSortableColumn="avlDate" style="width: 12rem">Avl Date<p-sortIcon field="avlDate">
                    </p-sortIcon>
                  </th>
                </tr>


              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr class="cardTable">

                  <td>

                    <span
                      style="float: left;font-size: 15px;font-weight: 550;margin-top: 10px;margin-left: 5px;">{{rowData.orderNo}}</span>
                     <!-- <button mat-icon-button [matMenuTriggerFor]="menu" id="btncontectMenu"
                      aria-label="Example icon-button with a menu" (keydown.enter)="$event.preventDefault()">
                      <mat-icon style="font-size: 20px; cursor: pointer;" (keydown.enter)="$event.preventDefault()">
                        more_vert
                      </mat-icon>
                    </button>


                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="navToSupersession(rowData)" id="btnnavToSupersession">
                        <mat-icon>add_task</mat-icon>
                        <span>Supersession Inquiry</span>
                      </button>

                    </mat-menu>  -->
                    <p-menu #menu [popup]="true" appendTo="body" [model]="items"></p-menu>
                    
                    <mat-icon  style="font-size: 18px; cursor: pointer;margin-top: 12px;"
                    (click)="menu.toggle($event);selectedrow(rowData)">more_vert
                                            </mat-icon>

                    <span style="float: right;font-size: 15px;font-weight: 550;margin-top: 10px;margin-right: 5px;">
                      {{rowData.registerNo}}</span>

                  </td>
                  <td style="text-align: left;">

                    <a (click)="navToPartInq(rowData)" id="navToPartInq"
                      style="cursor: pointer;text-align: center;color: blue;text-decoration: underline;">
                      {{rowData.partNo}}
                    </a>
                    <div style="float: right;">
                      <span style="font-size: 15px;font-weight: 550;">Quantity: </span>
                      <span style="font-size: 14px;">{{rowData.quantityOrder}}</span>

                    </div>



                  </td>
                  <td style="text-align: left;">

                    {{rowData.type}}
                    <div style="float: right;">
                      <span style="font-size: 15px;font-weight: 550;">Item: </span>
                      <span style="font-size: 14px;">{{rowData.item}}</span>
                    </div>
                  </td>
                  <td style="text-align: left;font-size: 14px;">
                    {{rowData.availableDate}}
                    <span style="float: right" *ngIf="rowData.adviceCode && rowData.adviceCode!=''"
                      class="tag">{{rowData.adviceCode}}</span>
                    <!-- <span style="float: right" *ngIf="rowData.partNumber == '' " class="tag">UNKN</span> -->

                  </td>



                </tr>
              </ng-template>

            </p-table>
          </div>
         
    
        </mat-card-content>
      </mat-card>
     
    </mat-grid-tile>
  </form>
</div>