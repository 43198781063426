import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminStackModel } from '../admin-stack/model/AdminStackModel';

@Injectable({
    providedIn: 'root'
})
export class AdminStackService {

    token: string;
    httpOptions;
    marketValue: string;

    constructor(private http: HttpClient) {
        this.token = 'Bearer '+ sessionStorage['gateway_access_token']
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':this.token, 'market': 'PANDACOMMON' })
        }
    }

    getHomeWithDateMarket(value: string): Observable<any> {
        return this.http.get(environment.baseUrl + 'v1/homeScreen/' + value, this.httpOptions);
    }

    getAdminDetailsWithMarket(value: string): Observable<any> {
        return this.http.get(environment.baseUrl + 'v1/adminStack/' + value, this.httpOptions);
    }

    getAllAdminDetails(): Observable<any>{
        return this.http.get(environment.baseUrl + 'v1/adminStackDetails', this.httpOptions);
    }

    createAdminDetails(adminStackModel: AdminStackModel): Observable<any> {
        return this.http.post<any>(environment.baseUrl + 'v1/saveAdminDetails', adminStackModel, this.httpOptions);
    }

    deleteAdminDetails(Id: number): Observable<object> {
        return this.http.delete(environment.baseUrl + 'v1/adminStack/delete/' + Id, this.httpOptions);

    }
    getMarketValue(value: string) {
        this.marketValue = value;
    }

    getMaxValueForInsertAdmin(): Observable<any> {
        return this.http.get(environment.baseUrl + 'v1/adminStack/maxValue', this.httpOptions);
    }

    getValueByDate() {
        return this.http.get(environment.baseUrl + 'v1/adminStack/Date', this.httpOptions);
    }
}
