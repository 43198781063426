export class Details{
    partNumber:string;
    quantitySupplied:number;
    packingSlipNumber:number;
    asnNumber:number;
    cumQty:number;
    invoiceNumber:string;
    invoiceDate:string;
    vendorCode:string;
    caseNumber:string;
    stringAsnNumber:string;
}