import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';

import { tap } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

/** This class is an implementation of an HttpInterceptor
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	public hostUrl = environment.hostUrl;
	constructor(private router: Router) {
	}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				Authorization: 'Bearer ' + sessionStorage.getItem('gateway_access_token')
			}
		});
		//return next.handle(request);
		return next.handle(request).pipe(tap(() => {
			},
			(err: any) => {
				// console.log('Intercepter Before HttpErrorResponse-->');
				if (err instanceof HttpErrorResponse) {
					//console.log('Intercepter -->', request.url);
					if (err.status === 401) {
						//console.log('Intercepter 401 -->', request.url);
						document.location.href = 'https://wwwqa.faust.idp.ford.com/logout?back=' + this.hostUrl;
					}
					return;
				}
			}));
	}
}
