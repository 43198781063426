import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class OauthTokenService {
	webUrl : string;
	token: string;
	httpOptions;

	constructor(private http: HttpClient) {}

	getToken(): Observable<any> {
		const body = new URLSearchParams({
			// resource: environment.resourceId,
			// client_id: environment.clientId,
			// client_secret: environment.clientSecret,
			grant_type: 'client_credentials'
		});

		const header = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded'
		});

		return this.http.post(`adfs/oauth2/token`, body.toString(), {headers: header})
	}

	getApiModuleToken(resourceId: string): Observable<any> {
		const body = new URLSearchParams({
			resource: resourceId,
			client_id: '59b7c387-6658-b870-26f0-d915af4fd643',
			client_secret: 'dLa1om9FpmZcLMT3cyaeIXzSwPj0DCIxgREq9Fgf',
			grant_type: 'client_credentials'
		});

		const header = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded'
		});

		return this.http.post(`adfs/oauth2/token`, body.toString(), {headers: header})
	}

	fetchRole(userId){
		this.token = 'Bearer '+sessionStorage['gateway_access_token'];
		this.httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'Authorization':this.token}),
		};
		this.webUrl = environment.orderUrl + 'api/v1/mdpdecision/';
		//this.webUrl = environment.webUrl;
		return this.http.get(this.webUrl+userId, this.httpOptions);
	}
}
