import {Component, HostListener} from '@angular/core';
import {MenuItem} from 'primeng/api';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss']
})
export class NavComponent {
	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	/** whether or not the sidebar is open or not */
	sidebarVisible: boolean;
	/** toggles visibility of sidebar to true */
	showSidebar = () => { this.sidebarVisible = true; };
	/** toggles visibility of sidebar to false */
	hideSidebar = () => { this.sidebarVisible = false; };

	/** Create a nav component and populate with necessary MenuItems (static data) */
	constructor() {
		this.navMenuItems = [
			{
				label: 'Dealer Order',
				routerLink: '/dealer-order',
				command: this.hideSidebar
			}
		];
	}
}
