import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { DatePipe, formatDate } from '@angular/common'
import { NavigationService } from 'src/app/services/navigation.service';
//import { App as CapacitorApp} from '@capacitor/app';
interface PartsEnq {
  name: string,
  code: string
}
@Component({
  selector: 'parts-inquiry',
  templateUrl: './parts-inquiry.component.html',
  styleUrls: ['./parts-inquiry.component.scss'],
  providers: [CommonService, DatePipe]
})
export class PartsInquiryComponent implements OnInit {
	showmobFilter: boolean = false;
	enablePartDetail:boolean = false;
  submitted = false;
  generalpartEnquiryForm: FormGroup;
  partDetailForm: FormGroup;
  parts: PartsEnq[];
  stateOptions: any[];
  selectedParts: PartsEnq;
  partTab: boolean;
  showInvalidError:boolean;
  priceTab: boolean;
  backOrderVal: boolean;
  restrictionVal:boolean;
  curURL;
  showErrorMsg: boolean = false;
  showFilter:boolean = false;
  dispalyPartNumber: string;
  backActive: boolean;
  utcDate: any;
  
  constructor(public activatedRoute: ActivatedRoute,
    private dealerService: CommonService,
    private router: Router,
    public datepipe: DatePipe,
    private fb: FormBuilder,
    private zone: NgZone,
    private navigation: NavigationService) {
    this.loadPart();
    this.stateOptions = [
      { label: 'No', value: 'off' },
      { label: 'Yes', value: 'on' }
    ];
    // CapacitorApp.addListener('backButton', ({ canGoBack })=> {
    //   if (!canGoBack) {
    //     CapacitorApp.exitApp();
    //   } else {
    //     this.zone.run(() => {
    //       this.searchOrder();
    //     });
    //   }
    // });
  }
	showFilterOpt() {
		this.showFilter = true;
		this.showmobFilter = false;
	}
  ngOnInit(): void {
    this.backActive = this.navigation.isBackActive;
    this.partTab = true;
    this.priceTab = true;
    this.backOrderVal = false;
    this.restrictionVal = false;
    this.initialisePartEnquiryForm();
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.partNumber && params.partNumber.trim() !== '') {
      this.fetchPartDeatil(params.partNumber);
      } else {
        this.showFilter = true;
      }
    });
    this.getDateTime();
  }
  getDateTime()
  {
    const today = new Date();
    this.utcDate=formatDate(today, 'dd/MM/yyyy hh:mm a', 'en-US', 'UTC+4');
   
    console.log("utcdate",this.utcDate);
  }
  formatPartNumber() {
    let part = this.generalpartEnquiryForm.value.partNumber.replace(/\s/g, '').toUpperCase();
    let partnumval=part.replace(/[^a-zA-Z 0-9]+/g,"");
    this.generalpartEnquiryForm.controls['partNumber'].setValue(partnumval);
  }
  submit(){
     this.fetchPartDeatil(this.generalpartEnquiryForm.value.partNumber);

  }
  fetchPartDeatil(partNumber)
  {
    if(partNumber)
     {
      let country=sessionStorage.getItem('country');
		  this.dealerService.fetchPartDetail(partNumber).subscribe(data => {
			console.log(JSON.stringify(data));
      if (data && data['backOrder'] === 'Y') 
      {
				this.backOrderVal = true;
      }
      if(data && data['onRestriction'] === '1')
      {
        this.restrictionVal = true;
      }
      else if (data && data['onRestriction'] === '0')
      {
        this.restrictionVal = false;
      }
      if(data === null)
      {
        this.showInvalidError=true;
        this.enablePartDetail = false;
        console.log("invalid error"+this.showInvalidError);
        
      }
      else{
      this.generalpartEnquiryForm.controls['partNumber'].setValue(data['partNumber'].replace(/\s/g, ''));
      this.generalpartEnquiryForm.controls['partNumberVal'].setValue(data['partNumber']);
			this.generalpartEnquiryForm.controls['engineeringPartNo'].setValue(data['engineeringPartNumber']);
			this.generalpartEnquiryForm.controls['desc'].setValue(data['description']);
			this.generalpartEnquiryForm.controls['hsn'].setValue(data['npno']);
			this.generalpartEnquiryForm.controls['finis'].setValue(data['finis']);
			this.generalpartEnquiryForm.controls['priceCodeBrand'].setValue(data['priceCodeBrand']);
			this.generalpartEnquiryForm.controls['available'].setValue(data['availableQty']);
			this.generalpartEnquiryForm.controls['marketingcode'].setValue(data['marketingCode']);
			this.generalpartEnquiryForm.controls['inventoryStatus'].setValue(data['status']);
			this.generalpartEnquiryForm.controls['procurementCode'].setValue(data['procurementCode']);
			this.generalpartEnquiryForm.controls['modelApplication'].setValue(data['model']);
			this.generalpartEnquiryForm.controls['overpackQty'].setValue(data['overPackQty']);
			this.generalpartEnquiryForm.controls['unitpackQty'].setValue(data['unitPackQty']);
			this.generalpartEnquiryForm.controls['weight'].setValue(data['weight']);
			this.generalpartEnquiryForm.controls['unitcube'].setValue(data['unitCube']);
			this.generalpartEnquiryForm.controls['replacedBy'].setValue(data['replacedBy']);
			this.generalpartEnquiryForm.controls['replacing'].setValue(data['replacing']);
			this.generalpartEnquiryForm.controls['comments'].setValue(data['npno']);
			this.generalpartEnquiryForm.controls['dealerPrice'].setValue(data['distributedPrice']);
			//this.generalpartEnquiryForm.controls['discountCode'].setValue(data['discountCode']);
			this.generalpartEnquiryForm.controls['deposit'].setValue(data['npno']);
			this.generalpartEnquiryForm.controls['vat'].setValue(data['gst']);
			this.generalpartEnquiryForm.controls['bulkBuyl1'].setValue(data['bulkBuyLevel1']);
			this.generalpartEnquiryForm.controls['bulkBuyl2'].setValue(data['bulkBuyLevel2']);
			this.generalpartEnquiryForm.controls['LifetimeWarrany'].setValue(data['warranty']);
			this.generalpartEnquiryForm.controls['height'].setValue(data['height']);
			this.generalpartEnquiryForm.controls['width'].setValue(data['width']);
			this.generalpartEnquiryForm.controls['depth'].setValue(data['depth']);
			this.showFilter=false;
			this.showmobFilter = true;
			this.enablePartDetail = true;
      this.showErrorMsg = false;
      this.showInvalidError=false;
      }
		});
	}
  	else{
    this.showErrorMsg = true;
    this.showInvalidError=false;
	}
  }
  	getTokenInfo(token: string): any {
    return JSON.parse(atob(token.split('.')[1]));
  }
  responseHandler(response) {
    const roleAccess = ['PANDA:PartInquiry:WithPriceData'];
    response.Permitted.forEach(element => {
      this.priceTab = element.read.some( (v)=> {
        return roleAccess.indexOf(v) >= 0;
      });
    });
  }
  get f() { return this.generalpartEnquiryForm.controls; }
  back() {
    this.partTab = false;
  }
  searchOrder() {
    this.curURL = this.navigation.fetchUrl();
    this.router.navigate([this.navigation.fetchUrl()]);
  }
  navToPartInq() {
    this.navigation.setUrl('parts-enquiry/' + this.generalpartEnquiryForm.value.partNumber);
    const partno = this.generalpartEnquiryForm.value.replacedBy.replace(/\s/g, '');
    console.log('PARTNUMBERRRRRRR ' + partno)
   // this.dealerService.partNumberFilter = this.partNumberSearch.getRawValue();
    this.router.navigate(['parts-enquiry/' + partno]);
  }
  navToReplacePartInq(){
    this.navigation.setUrl('parts-enquiry/' + this.generalpartEnquiryForm.value.engineeringPartNo);
    const partno = this.generalpartEnquiryForm.value.engineeringPartNo.replace(/\s/g, '');
    console.log('PARTNUMBERRRRRRR ' + partno)
   // this.dealerService.partNumberFilter = this.partNumberSearch.getRawValue();
    this.router.navigate(['parts-enquiry/' + partno]);
  }
  searchMobilePart() {
    this.partTab = true;
    console.log('Inside part inquiry');
    console.log(this.generalpartEnquiryForm.value.partNumberVal);
  }
  searchPart() {
    this.submitted = true;
    if (this.generalpartEnquiryForm.invalid) {
      return;
    }
    this.partTab = true;
    console.log('Inside part inquiry');
    console.log(this.generalpartEnquiryForm.value.partNumberVal);
  }
  initialisePartEnquiryForm() {
    this.generalpartEnquiryForm = this.fb.group({
      partNumber: [''],
      engineeringPartNo: [''],
      desc: [''],
      available:[''],
      hsn: [''],
      finis: [''],
      priceCodeBrand: [''],
      maintananedDate: [''],
      marketingcode: [''],
      inventoryStatus: [''],
      procurementCode: [''],
      modelApplication: [''],
      partNumberVal:[''],
      overpackQty: [''],
      unitpackQty: [''],
      weight: [''],
      unitcube: [''],
      replacedBy: [''],
      replacing: [''],
      comments: [''],
      dealerPrice: [''],
      deposit: [''],
      vat: [''],
      bulkBuyl1: [''],
      bulkBuyl2: [''],
      LifetimeWarrany: [''],
      height:[''],
      width:[''],
      depth:['']
    });
  }
  clear() {
    this.initialisePartEnquiryForm();
    this.enablePartDetail=false;
    this.showErrorMsg=false;
    this.showInvalidError=false;
  }
  loadPart() {
    this.parts = [
      { name: 'Ford or Mazda ', code: 'NY' },
      { name: 'Ford Engineering', code: 'RM' },
      { name: 'Finis', code: 'LDN' },
      { name: 'Vendor', code: 'IST' }
    ];
  }
}
