import {
	Component,
	OnInit,
	VERSION, NgZone
} from '@angular/core';
import {environment} from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import {filter} from 'rxjs/operators';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { App as CapacitorApp } from '@capacitor/app';
/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	idleState = 'Not started.';
	timedOut = false;
	public hostUrl = environment.hostUrl;
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;
	/** constructor for setting up DI in this component */
	constructor(private readonly router: Router,private idle: Idle,
		private navigation: NavigationService,
		private zone: NgZone) {
		// IDLE TIMEOUT - 3600 secs
		idle.setIdle(3600);
		// sets a timeout period of 60 seconds. after 10 seconds of inactivity, the user will be considered timed out.
		idle.setTimeout(60);
		// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
		idle.onTimeout.subscribe(() => {
			this.idleState = 'Timed out!';
			this.timedOut = true;
		});

		idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
		idle.onTimeoutWarning.subscribe((countdown) => {

			this.idleState = 'You will timevxc out in ' + countdown + ' seconds!';
			if (countdown === 1) {
				localStorage.clear();
				sessionStorage.clear();
				document.location.href = 'https://wwwqa.faust.idp.ford.com/logout?back=' + this.hostUrl;
				}
		});
		//sets the ping interval to 15 seconds
		CapacitorApp.addListener('backButton', ({ canGoBack }) => {
			if (!canGoBack) {
				CapacitorApp.exitApp();
			} else {
				this.zone.run(() => {
					this.router.navigate([this.navigation.fetchUrl()]);
				});
			}
		});
		this.reset();

	}
	/** this class requires this method to implement the OnInit interface */
	ngOnInit() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
			});
	}

	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string) {
		if(!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}
	reset() {
		this.idle.watch();
		this.idleState = 'Started.';
		this.timedOut = false;
		}
}
