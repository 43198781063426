import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class VendorDetailsService {

    token: string;

    constructor(private http: HttpClient) { 
        this.token = 'Bearer '+ sessionStorage['gateway_access_token'];
    }

    getVendorDetails(gsdb: string, marketId: string): Observable<any> {
        return this.http.get(environment.baseUrl + "v1/vendordetails/" + gsdb, { headers: { 'Content-Type': 'application/json', 'market': marketId, 'Authorization':this.token } });
    }
}