import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';
import { CommonService } from '../services/common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { formatDate } from '@angular/common';
interface Country {
  name: string;
  code: string;
}
@Component({
  selector: 'supercession-inquiry',
  templateUrl: './supercession-inquiry.component.html',
  styleUrls: ['./supercession-inquiry.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class SupercessionInquiryComponent implements OnInit {
  countries: any[];
  showmobFilter: boolean = true;
  selectedCountries: any[];
  items: MenuItem[];
  dealerOptions: boolean = false;
  partNumberOptions: boolean = false;
  supercessionInquiry: FormGroup;
  orderList: any[];
  partOrderList: any[];
  dealerList;
  selectedVal: any;
  orderDetailTableEnable: boolean;
  datatableRequest;
  partDetailTableEnable: boolean;
  submitted = false;
  cols: any[];
  partCols: any[];
  showIcon: boolean = true;
  tableVal: any[] = [];
  tableValLength: number;
  totalRecords: number;
  //loading: boolean;
  searched: boolean = false;
  invalid: boolean = false;
  first = 0;
  rows = 5;
  checked: boolean;
  superSessionRequest;
  marked = false;
  accepted: boolean = true;
  rowsPerPageOptions = 5;
  dealerChanged = false;
  showFilter: boolean = true;
  accountTableValues: any[];
  exportColumns: any[];
  curURL;
  backActive;
  showError: boolean = false;
  utcDate: string;
  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.backActive = this.navigation.isBackActive;
    this.initialiseDealerOrderInquiryForm();
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.partNumber && params.partNumber.trim() !== '') {
        console.log('***** Param Value ****');
        console.log(params.partNumber);
        this.supercessionInquiry.controls['partNumber'].setValue(
          params.partNumber
        );
        this.searchDealer();
      }
    });
    console.log(
			'supersessionfilter' +
				JSON.stringify(this.commonService.superSessionFilter)
		);
		if (
			this.commonService.superSessionFilter &&
			Object.keys(this.commonService.superSessionFilter).length > 0
		) {
			this.restoreFilter();
			this.backActive = false;
		} else {
			this.showFilter = true;
		}
    this.getDateTime();
  }
  getDateTime()
  {
    const today = new Date();
    this.utcDate=formatDate(today, 'dd/MM/yyyy hh:mm a', 'en-US', 'UTC+4');
   
    console.log("utcdate",this.utcDate);
  }
  initialiseDealerOrderInquiryForm() {
    this.supercessionInquiry = this.fb.group({
      partNumber: [''],
    });
  }
  restoreFilter() {
   
    this.datatableRequest = {
      filters: {
        partNumber: this.navigation.superSessionFilter?.partNumber.replace(
          /\s/g,
          ''
        ),
      },
      startingRecordNumber: 0,
        //  sortField: 'partNumber',
        // sortOrder: 1
    };
    this.supercessionInquiry.get('partNumber').setValue(this.commonService.superSessionFilter?.partNumber);
    // this.supercessionInquiry.get('partNumber').setValue(this.commonService.superSessionFilter?.partNumber);
    this.getTableData();
  }
  
  navToPartInq(rowData) {
    this.navigation.isBackActive = true;
    const partno = rowData.partNumber.replace(/\s/g, '');
    console.log('PARTNUMBERRRRRRR ' + partno)
    this.commonService.superSessionFilter = this.supercessionInquiry.getRawValue();

      this.navigation.setUrl('dealer/dealer-supersession');
      this.router.navigate(['parts-enquiry/' + partno]);

    

  }

  navToPartInqReplaced(rowData) {
    this.navigation.setUrl('dealer/dealer-supersession');
    const partno = rowData.replacedBy.replace(/\s/g, '');
    console.log('PARTNUMBERRRRRRR ' + partno);
    this.navigation.superSessionFilter = this.supercessionInquiry.getRawValue();
    this.router.navigate(['parts-enquiry/' + partno]);
  }
  navToPartSearch() {
    this.navigation.isBackActive = false;
    this.curURL = this.navigation.fetchUrl();
    this.router.navigate([this.navigation.fetchUrl()]);
  }
  responseHandler(response) {
    this.tableVal = response;
    console.log('tableVal length :', this.tableVal.length);
    this.tableValLength = this.tableVal.length;
    this.totalRecords = response.length;
  }
  isLastPage(): boolean {
    return this.orderList
      ? this.first === this.orderList.length - this.rows
      : true;
  }
  isFirstPage(): boolean {
    return this.orderList ? this.first === 0 : true;
  }
  formatPartNumber() {
    let part = this.supercessionInquiry.value.partNumber.replace(/\s/g, '').toUpperCase();
    let partnumval=part.replace(/[^a-zA-Z 0-9]+/g,"");
    this.supercessionInquiry.controls['partNumber'].setValue(partnumval);
  }
  searchDealer() {
    console.log('*** part number value');
    console.log(this.supercessionInquiry.value.partNumber);
    
    if (this.supercessionInquiry.value.partNumber) {
      
      // this.submitted = true;
      this.showError = false;
      this.searched = false;
      // const part = this.supercessionInquiry.value.partNumber
      // this.supercessionInquiry.get('partNumber').setValue(part);
      this.getTableData();
      console.log('getdata ' + this.showError);
    } else {
      this.showError = true;
      this.searched = false;
      console.log('getdata error' + this.showError);
    }
  }
  getTableData() {
    // this.commonService
    //   .fetchPartHistory(this.supercessionInquiry.value.partNumber)
    //   .subscribe((data) => {
    //     this.responseHandler(data);
	const part = this.supercessionInquiry.value.partNumber;
	let country=sessionStorage.getItem('country');
        this.commonService.fetchPartHistory(part).subscribe((data) => {this.responseHandler(data);
        if (this.tableVal.length > 0) {
          this.searched = false;
          this.showFilter = false;
          this.invalid = false;
          this.showmobFilter = true;
          this.submitted = true;
          this.commonService.superSessionFilter = [];

        } else {
          this.showFilter = true;
          this.invalid = true;
          this.searched = true;
        }
      });
  }

  showFilterOpt() {
    this.showFilter = true;
    this.showmobFilter = false;
  }
  clear() {
    this.initialiseDealerOrderInquiryForm();
    this.showError = false;
    this.supercessionInquiry.reset();
    this.submitted = false;
    this.dealerOptions = false;
    // this.partNumberOptions = false;
    this.orderDetailTableEnable = false;
    this.partDetailTableEnable = false;
    this.orderList = [];
    this.partOrderList = [];
    this.tableVal = [];
    this.tableVal.length = 0;
    this.searched = false;
    this.invalid = false;
    // this.marked = false;
    this.accepted = false;
    // this.checked=false;
  }
}
