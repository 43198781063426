import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService implements OnDestroy {
  orderUrl: string;
  partUrl: string;
  webUrl: string;
  partNumber: string;
  adviceCode: string;
  backorderFilter: any;
  partNumberFilter: any;
  superSessionFilter: any;
  filteredType:string;
  token: string;
  httpOptions;
  constructor(public http: HttpClient, public route: ActivatedRoute) {
    this.partUrl = environment.partUrl;
    this.orderUrl = environment.orderUrl;
    this.token = 'Bearer '+sessionStorage['gateway_access_token'];
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'Authorization':this.token}),
    };
  }

  ngOnDestroy() {

  }
//   fetchModelCodes(country:string) {
// 	return this.http.get(this.partUrl + `api/v1/part/inquiry/models`,
// 	{headers: new HttpHeaders({'Content-Type' : 'application/json' , countryCode : country})});
// }
	fetchModelCodes() {
		return this.http.get(this.partUrl + `api/v1/part/inquiry/models`);
	}
	fetchReferanceNo(partNumber) {
		return this.http.get(this.partUrl + `api/v1/part/inquiry/refNumber/` + partNumber);
	}
	fetchPartSearchList(dataTableRequest) {
		return this.http.post(
			this.partUrl + `api/v1/part/inquiry/search`,
			dataTableRequest
			
		);
	}
	fetchPartSearchCount(dataTableRequest) 
	{
		return this.http.post(this.partUrl + `api/v1/part/inquiry/search/count`,dataTableRequest);
	}
	fetchPartHistory(partNumber) 
	{
		return this.http.get(this.partUrl + `api/v1/part/inquiry/history/` + partNumber);
	}
	fetchPartDetail(partNumber)
	{
		return this.http.get(this.partUrl + 'api/v1/part/inquiry/' + partNumber);
	}
	fetchRole(userId)
	{
		return this.http.get(this.orderUrl + 'api/v1/mdpdecision/' + userId);
	}
	fetchDealerCode(dealerCode)
	{
		return this.http.get(this.orderUrl +'api/v1/order/inquiry/code/'+ dealerCode);
	}
	fetchBackOrder(dataTableRequest) {
		return this.http.post(this.orderUrl + `api/v1/order/inquiry/bo/search`,dataTableRequest);
	}
	fetchBackOrderExcel(dataTableRequest) {
		return this.http.post(this.orderUrl + `api/v1/order/inquiry/bo/search/excel`,dataTableRequest);
	}
	getCDSID(userType: string, userId: string)
	{
    return this.http.get<any>(environment.baseUrl + 'v1/supplier/'+userType+'/'+userId);
	  }
	
}
