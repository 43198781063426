export class PartNumberDetails{
    refNo?:number;
    delvDate?:string;
    vdr?:string;
    ordClassValue?:string;
    ordType?:string;
    satisfied?:number;
    firm?:number;
    orderNo?:string;
    cretDate?:string;
    osQtyValue?:string;
    authQty?:number;
    itemVodOsQty?:string;
    itemVodRctQty?:number;
    itemVodMtChng?:number;
    itemVodCumChng?:number;
    authTotal?:number;
    recTotal?:number;
    cumOrder?:number;
    cumReceipt?:number;
    exwYmd?:string;
    estimatedTimeToDelv?: Date;
}

export class Ordercount{
    orderCount?:number;
    receiptCount?:number;
}