<div style="min-height: 88vh;">
    <form [formGroup]="supercessionInquiry" style="padding: 0%;">
        <mat-grid-tile style="background-image: url('../../assets/image/mache1.jpg');">
            <mat-card class="basecard">
                <div class="flex pb-2 mb-2 HeadTitle">
                    <div class="mr-2 ml-2">
                        <mat-icon class="backIcon" (click)="navToPartSearch()" *ngIf="backActive" style="cursor: pointer;" id="btnBack">keyboard_arrow_left</mat-icon>
                        <mat-icon class="titleIcon" *ngIf="showIcon"> add_task</mat-icon>
                    </div>

                    <div class="mr-2 orderHead">
                        <span>
                            SUPERCEDED PART INQUIRY
                        </span>
                    </div>
                    <div class="flex-grow-1"></div> <!-- This will push the following span to the right -->
                    <div class="mr-2 orderHead" >
                        <span>{{ this.utcDate }}</span>
                    </div>

                </div>

                <mat-card-content>
                    <div class="formgrid p-grid web shadow-6">
                        <div class="p-grid">
                            <div class="field col-12 col-md-3 divbody mt-2">
                                <label for="dealer" class="formLabels">Superceded Part History For </label>
                                <input
                                    class="form-control m-input"
                                    id="partHistory"
                                    type="text"
                                    required
                                    (keydown.enter)="searchDealer()"
                                    (keyup)="formatPartNumber()"
                                    formControlName="partNumber"
                                    pInputText
                                />
                                <small id="username2-help" class="errorMessage" style="color: red; font-size: 13px; font-weight: 500;" *ngIf="showError">Please enter Part Number</small>

                                <small id="username2-help" class="errorMessage" style="color: red;" *ngIf="searched">Please enter valid Part Number </small>
                            </div>
                        </div>

                        <div class="flex justify-content-center">
                            <div class="mr-2">
                                <button pButton id="btnSearch" type="button" label="Search" icon="pi pi-search" class="btn-Search mb-2 mr-2" (click)="searchDealer()" (keydown.enter)="searchDealer()" (keyup.enter)="searchDealer()"></button>
                            </div>
                            <div class="mr-2"><button pButton id="btnClear" type="button" label="Clear" icon="pi pi-times" class="btn-clear mb-2" (click)="clear()"></button></div>
                        </div>
                    </div>
                    <div class="formgrid p-grid mob">
                        <mat-accordion *ngIf="showFilter  ">
                            <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header style="height: 35px !important;">
                                    <mat-panel-title> Filter Options </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="field col-12 col-md-4 divbody">
                                    <input
                                        class="form-control m-input"
                                        id="partHistory"
                                        type="text"
                                        formControlName="partNumber"
                                        placeholder="Superceded Part History for"
                                        (keyup)="formatPartNumber()"
                                        pInputText
                                    />
                                    <small id="username2-help" class="errorMessage" style="color: red; font-size: 13px; font-weight: 500;" *ngIf="showError">Please enter Part Number</small>

                                    <small id="username2-help" class="errorMessage" style="color: red;" *ngIf="searched && tableVal.length===0   ">Please enter valid Part Number </small>
                                </div>

                                <div class="flex justify-content-center">
                                    <div class="mr-2">
                                        <button
                                            pButton
                                            id="btnSearch"
                                            type="button"
                                            label="Search"
                                            icon="pi pi-search"
                                            class="btn-Search mb-2 mr-2"
                                            (click)="searchDealer()"
                                            (keydown.enter)="searchDealer()"
                                            (keyup.enter)="searchDealer()"
                                        ></button>
                                    </div>
                                    <div class="mr-2"><button pButton id="btnClear" type="button" label="Clear" icon="pi pi-times" class="btn-clear mb-2" (click)="clear()"></button></div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <div class="webTable shadow-6" *ngIf="tableVal.length>0">
                        <p-table
                            #dt1
                            [columns]="cols"
                            [value]="tableVal"
                            styleClass="p-datatable-responsive-demo"
                            id="webTable"
                            class="shadow-2 ml-2 mr-2"
                            [lazy]="true"
                            (onRowSelect)="onRowSelect($event)"
                            [rows]="10"
                            [showCurrentPageReport]="true"
                            [loading]="loading"
                            styleClass="p-datatable-customers"
                            [paginator]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="totalRecords"
                        >
                            <ng-template pTemplate="header" let-columns>
                                <tr class="tablehead">
                                    <th class="partNumberHeader">Part Number</th>
                                    <th class="invstatusHeader">Inventory Status</th>
                                    <th class="replacedByHeader">Replaced By</th>
                                    <th class="replaceDate">Replaced Date</th>
                                    <th class="quantity">Quantity</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <td style="text-align: center;">
                                        <a (click)="navToPartInq(rowData)" id="navToPartInq" style="cursor: pointer; text-align: center; color: blue; text-decoration: underline;">
                                            {{rowData.partNumber}}
                                        </a>
                                    </td>
                                    <td style="text-align: center;">
                                        {{rowData.status}}
                                    </td>
                                    <td style="text-align: center;" (click)="navToPartInqReplaced(rowData)">
                                        {{rowData.replacedBy}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{rowData.replacing}}
                                    </td>
                                    <td>
                                        {{rowData.availableQty}}
                                        <!-- <button pButton pRipple type="button" label="Obselete"
                                                style="background-color: darkgray ; border-radius: 10px ;  font-size: 14px;"></button>-->
                                        <!-- <button pButton pRipple type="button" label="B"
                                                style="background-color: #3865a1 ; border-radius: 10px ;  font-size: 14px;"></button> -->
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div class="mobTable tablestyle">
                        <div class="flex mb-2" *ngIf="tableVal.length>0 && showmobFilter">
                            <button pButton label="Filter" class="mt-2 filterIcon" icon="pi pi-filter" (click)="showFilterOpt()"></button>
                        </div>
                        <p-table #dt1 [columns]="cols" [value]="tableVal" styleClass="p-datatable-responsive-demo" [lazy]="true" id="mobTable" (onRowSelect)="onRowSelect($event)" [totalRecords]="totalRecords" *ngIf="tableVal.length>0">
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr class="mt-2 cardTable">
                                    <!-- <div *ngIf="rowData.inventoryStatus!='CURRENT'"> -->
                                    <div>
                                        <!-- <td style="text-align: left;" >{{rowData.replacedBy}}</td> -->

                                        <td>
                                            <span (click)="navToPartInq(rowData)" style="font-size: 14px; cursor: pointer;"> {{rowData.partNumber}} </span>

                                            <span style="float: right; font-size: 14px;"> {{rowData.status}} </span>
                                        </td>
                                        <td style="text-align: left; font-size: 14px;" *ngIf="rowData.replacedBy"><span (click)="navToPartInqReplaced(rowData)" style="font-size: 14px; cursor: pointer;">{{rowData.replacedBy}}</span></td>
                                        <td style="text-align: left; font-size: 14px;" *ngIf="rowData.replacing">
                                            {{rowData.replacing}}
                                        </td>
                                        <td style="text-align: left; font-size: 14px;" *ngIf="rowData.availableQty">
                                            {{rowData.availableQty}}
                                        </td>
                                    </div>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </form>
</div>
