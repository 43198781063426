import {
	Component,
	OnInit,
	VERSION
} from '@angular/core';
import {
	NavigationEnd,
	Router
} from '@angular/router';
import { filter } from 'rxjs/operators';
import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, OnDestroy} from '@angular/core';
import {NavigationService} from '../services/navigation.service';
import {environment} from 'src/environments/environment';
import {CommonService} from '../dealer/services/common.service';
@Component({
	selector: 'common-landing',
	templateUrl: './common-landing.component.html',
	styleUrls: ['./common-landing.component.scss']
})
export class CommonLandingComponent implements OnInit {
	mobileQuery: MediaQueryList;
	public hostUrl = environment.hostUrl;
	multiDealerEnable:boolean = false;
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	dealerId: String;
	userId: string;
	isProfileClicked = false;
	dealerCode: any[]
	errorMsg: boolean = false;
	selectedCategory: any;
	displayMaximizable: boolean;
	allNavMenuItems = [
		{
			label: 'Dealer',
			icon: 'handyman',
			children: [{
				label: 'Home',
				routerLink: '/common/dashboard',
				icon: 'home',
				routerLinkActive:'is-active'
			},
			{
				label: 'Part Search',
				routerLink: '/common/dealer-part-search',
				icon: 'manage_search'
			},
			{
				label: 'Backorder Inquiry',
				routerLink: '/common/back-order',
				icon: 'post_add'
			},
			{
				label: 'Supersession Inquiry',
				routerLink: '/common/super-session',
				icon: 'add_task'
			},
			{
				label: 'Part Inquiry',
				routerLink: '/common/part-inquiry',
				icon: 'handyman'
			}]
		},
		{
			label: 'Supplier',
			icon: 'directions_car',
			children: [{
				label: 'Manage Announcement',
				routerLink: '/common/admin-stack',
				icon: 'home'
			},
			{
				label: 'Schedule Details By Part',
				routerLink: '/common/part-order-status',
				icon: 'manage_search'
			},
			{
				label: 'Vendor Parts Summary',
				routerLink: '/common/vendor-part-status',
				icon: 'post_add'
			},
			{
				label: 'In Transit Inquiry',
				routerLink: '/common/panda-trial-component',
				icon: 'add_task'
			},
			{
				label: 'Vendor Contact Details',
				routerLink: '/common/vendor',
				icon: 'handyman'
			}]
		}
	];
	permittedNavMenuItems = [];
	accpetablePermision = []

	/** constructor for setting up DI in this component */
	private _mobileQueryListener: () => void;
	userName: string;
	constructor(private readonly router: Router, changeDetectorRef: ChangeDetectorRef,
		private dealerService: CommonService,
				 private navigation: NavigationService, media: MediaMatcher) {
		this.mobileQuery = media.matchMedia('(max-width: 768px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}
	/** this class requires this method to implement the OnInit interface */
	ngOnInit() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
			});
		this.userName = sessionStorage.getItem('userName');
		sessionStorage['dealerCode'] = '';
		const tokenInfo = this.getTokenInfo(sessionStorage['gateway_access_token']);
		console.log(tokenInfo);
		const id = tokenInfo.sub;
		const countryCode = tokenInfo.country;
		console.log('******** Login Detail ******************')
		console.log(id);
		this.userId = id;
		sessionStorage['dealerId'] = id;
		console.log(countryCode);
		console.log(sessionStorage['dealerToken']);
		this.dealerId = sessionStorage['dealerCode'];

		if (sessionStorage['dealerToken'] == 'Test') {
			this.fetchDealerCode();
		}
		this.multiDealerEnable = sessionStorage['multiDealer'];
	}

	fetchDealerCode() {
		this.dealerService.fetchDealerCode(sessionStorage['dealerId']).subscribe(res => {
			this.responseHandler(res);
		});
	}

	swapDealer() {
		this.fetchDealerCode();
		this.selectedCategory = sessionStorage['dealerCode'];
	}

	navigateToBackOrder() {

		this.navigation.isBackActive = false;
		this.router.navigateByUrl('/dealer/dealer-backorder');
	}

	getTokenInfo(token: string): any {
		return JSON.parse(atob(token.split('.')[1]));
	}

	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string) {
		if (!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}

	showProfile(event) {
		this.isProfileClicked = !this.isProfileClicked;
		event.stopPropagation();
	}

	hideProfile() {
		this.isProfileClicked = false;
	}

	stopPropogation(event) {
		event.stopPropagation();
	}

	logout() {
		console.log('*** Logout ***');
		localStorage.clear();
		sessionStorage.clear();
		document.location.href = 'https://wwwqa.faust.idp.ford.com/logout?back=' + this.hostUrl;
	}

	responseHandler(response) {
		this.dealerCode = response;
		console.log('** Size **');
		console.log(response.length);
		if (response.length > 1) {
			sessionStorage['multiDealer'] = true;
			this.multiDealerEnable = true;
			this.displayMaximizable = true;
		} else {
			sessionStorage['dealerCode'] = response[0];
			this.dealerId = response[0];
			sessionStorage['dealerToken'] = '';
		}
	}

	setDealer() {
		console.log('*** Dealer Code ***');
		if (this.selectedCategory) {
			console.log(this.selectedCategory);
			sessionStorage['dealerCode'] = this.selectedCategory;
			sessionStorage['dealerToken'] = '';
			this.dealerId = this.selectedCategory;
			this.displayMaximizable = false;
		} else {
			console.log('*** Error Msg ***');
			this.errorMsg = true;
		}
	}

}
