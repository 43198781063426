import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AsnService } from '../services/asn.service';
import { Details } from './model/details.model';
import { Partdata } from './model/partdata.model';
import { NgForm } from '@angular/forms';
import { MarketData } from '../model/marketData';
import { Router } from '@angular/router';
import { transactionHistoryTableModel } from './model/transactionHistoryTableModel';
@Component({
  selector: 'advance-shipment-notification',
  templateUrl: './advance-shipment-notification.component.html',
  styleUrls: ['./advance-shipment-notification.component.scss']
})
export class AdvanceShipmentNotificationComponent implements OnInit {

  @ViewChild('asnFileUpload') asnFileUpload: NgForm;
  @ViewChild('asnScreen') asnScreen: NgForm;

  PartNumberArray: PartNumberArrayValue[] = [];
  partnum: PartNumberArrayValue;
  submitForm: boolean;
  enableForm: boolean;
  submitFormDate: boolean;
  transactionTblValue: transactionHistoryTableModel;
  detailsList: Details[] = [];
  responseValue: string[] = [];
  partNumberCheck: string[] = [];
  detailObject: Details;
  invoiceDate: Date;
  currentDate: Date;
  asnNumber: number;
  invoiceNumber: string = "";
  slipNumber: number;
  newInvNumber: string = "";
  caseNumber: string = "";
  partData: Partdata[] = [];
  invoiceList: Partdata[] = [];

  partNumber00: string = "";
  cummlativeQty01: number = 0;
  qtySupplied01: number = 0;

  partNumber01: string = "";
  cummlativeQty02: number = 0;
  qtySupplied02: number = 0;

  partNumber02: string = "";
  cummlativeQty03: number = 0;
  qtySupplied03: number = 0;

  partNumber03: string = "";
  cummlativeQty04: number = 0;
  qtySupplied04: number = 0;

  partNumber04: string = "";
  cummlativeQty05: number = 0;
  qtySupplied05: number = 0;

  partNumber05: string = "";
  cummlativeQty06: number = 0;
  qtySupplied06: number = 0;

  partNumber06: string = "";
  cummlativeQty07: number = 0;
  qtySupplied07: number = 0;

  partNumber07: string = "";
  cummlativeQty08: number = 0;
  qtySupplied08: number = 0;

  partNumber08: string = "";
  cummlativeQty09: number = 0;
  qtySupplied09: number = 0;

  partNumber09: string = "";
  cummlativeQty10: number = 0;
  qtySupplied10: number = 0;

  finalRowValue: number = 0;
  validFileName: string = "N";
  vendorCode: string = "";
  showOverlaySpinner: boolean;
  showOverlay: boolean;
  nullValues: number = 1;
  emptyValues: string = "N";
  map = new Map<number, Details>();
  localVendor: string = "N";
  emptyQtyValues: string = "N";
  fileName: string = "";
  partNumberMap: Map<string, boolean> = new Map<string, boolean>();
  partNumberCount: number = 0;
  maxDate: Date;
  today = new Date();
  packSlipMap = new Map([
	  [ 'A', 1 ],[ 'B', 2 ],[ 'C', 3 ],[ 'D', 4 ],[ 'E', 5 ],[ 'F', 6 ],[ 'G', 7 ],[ 'H', 8 ],[ 'I', 9 ],
	  [ 'J', 1 ],[ 'K', 2 ],[ 'L', 3 ],[ 'M', 4 ],[ 'N', 5 ],[ 'O', 6 ],[ 'P', 7 ],[ 'Q', 8 ],[ 'R', 9 ],
	  [ 'S', 2 ],[ 'T', 3 ],[ 'U', 4 ],[ 'V', 5 ],[ 'W', 6 ],[ 'X', 7 ],[ 'Y', 8 ],[ 'Z', 9 ]
	]);
  // Login Data
  cdsid: { id: string, isCovisint: boolean } = {
    id: '',
    isCovisint: false
  };
  marketData: MarketData;

  constructor(private messageService: MessageService, private asnService: AsnService, private datePipe: DatePipe) {

  }

  ngOnInit() {
    this.maxDate = new Date();
    this.marketData = JSON.parse(sessionStorage.getItem("market-data"));
    this.cdsid = JSON.parse(sessionStorage.getItem("cdsid"));
    this.vendorCode = this.marketData.gsdb;
    this.vendorCheck();
  }

  counter(i: number) {
    return new Array(i);
  }

  objectForSave() {
    if (this.partNumber00.length > 0 || this.cummlativeQty01 > 0 || this.qtySupplied01 > 0) {
      this.addingValuesToList(this.partNumber00, this.cummlativeQty01, this.qtySupplied01, 1);
    }
    if (this.partNumber01.length > 0 || this.cummlativeQty02 > 0 || this.qtySupplied02 > 0) {
      this.addingValuesToList(this.partNumber01, this.cummlativeQty02, this.qtySupplied02, 2);
    }
    if (this.partNumber02.length > 0 || this.cummlativeQty03 > 0 || this.qtySupplied03 > 0) {
      this.addingValuesToList(this.partNumber02, this.cummlativeQty03, this.qtySupplied03, 3);
    }
    if (this.partNumber03.length > 0 || this.cummlativeQty04 > 0 || this.qtySupplied04 > 0) {
      this.addingValuesToList(this.partNumber03, this.cummlativeQty04, this.qtySupplied04, 4);
    }
    if (this.partNumber04.length > 0 || this.cummlativeQty05 > 0 || this.qtySupplied05 > 0) {
      this.addingValuesToList(this.partNumber04, this.cummlativeQty05, this.qtySupplied05, 5);
    }
    if (this.partNumber05.length > 0 || this.cummlativeQty06 > 0 || this.qtySupplied06 > 0) {
      this.addingValuesToList(this.partNumber05, this.cummlativeQty06, this.qtySupplied06, 6);
    }
    if (this.partNumber06.length > 0 || this.cummlativeQty07 > 0 || this.qtySupplied07 > 0) {
      this.addingValuesToList(this.partNumber06, this.cummlativeQty07, this.qtySupplied07, 7);
    }
    if (this.partNumber07.length > 0 || this.cummlativeQty08 > 0 || this.qtySupplied08 > 0) {
      this.addingValuesToList(this.partNumber07, this.cummlativeQty08, this.qtySupplied08, 8);
    }
    if (this.partNumber08.length > 0 || this.cummlativeQty09 > 0 || this.qtySupplied09 > 0) {
      this.addingValuesToList(this.partNumber08, this.cummlativeQty09, this.qtySupplied09, 9);
    }
    if (this.partNumber09.length > 0 || this.cummlativeQty10 > 0 || this.qtySupplied10 > 0) {
      this.addingValuesToList(this.partNumber09, this.cummlativeQty10, this.qtySupplied10, 10);
    }
  }

  saveFunctionalityMain() {
    if (this.emptyQtyValues === "N") {
      this.asnService.setDetailsValue(this.detailsList, this.marketData.marketId).subscribe(data => {
        this.responseValue = data;
        if (data == "Saved Successfully") {
          this.clearAll();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Successfully Submitted', id: 'pop-up-message', life: 5000 });
          this.saveTransactionTableHistory("Ok");
          this.submitForm = false;
          this.submitFormDate = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.responseValue[0], id: 'pop-up-message', life: 5000 });
        }
      })
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Quantity Shipped Value must be greater than 0', id: 'pop-up-message', life: 5000 });
      this.emptyQtyValues = "N";
    }
  }

  invoicDateCheck() {
    this.submitFormDate = false;
  }

  onOkClick() {
    this.detailsList = [];
    this.nullValues = 1;
    this.detailObject = new Details;
    if (this.slipNumber > 0) {
      if (new Date(this.invoiceDate) > new Date(formatDate(this.today, 'yyyy-MM-dd', 'en-US'))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Future Invoice Date is not allowed', id: 'pop-up-message', life: 5000 });
      } else {
        this.objectForSave();
        if (this.detailsList.length > 0) {
          if (this.emptyValues === "N") {
            this.saveFunctionalityMain();
          } else {
            if (this.emptyValues === "P") {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Enter The Part Number', life: 5000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Empty Part Number Value found in the middle', life: 5000 });
            }
            this.emptyValues = "N";
            this.emptyQtyValues = "N";
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter atleast one Partnumber and Quantity', life: 5000 });
        }
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Packing Slip Number is Mandatory', id: 'pop-up-message', life: 5000 });
    }
  }

  addingValuesToList(partNumber: string, cumQty: number, qtySupplied: number, rowValue: number) {
    this.detailObject = new Details;
    if (partNumber.trim().length > 0) {
      this.detailObject.partNumber = partNumber.toUpperCase();
    }
    this.detailObject.packingSlipNumber = this.slipNumber;
    this.detailObject.cumQty = Number(cumQty);
    // this.detailObject.invoiceNumber = this.invoiceNumber.toUpperCase();
	this.detailObject.invoiceNumber = this.newInvNumber.toUpperCase();
    this.detailObject.quantitySupplied = Number(qtySupplied);
    this.detailObject.asnNumber = this.asnNumber;
    if (this.caseNumber) {
      this.detailObject.caseNumber = this.caseNumber.toUpperCase();
    } else {
      this.detailObject.caseNumber = this.caseNumber;
    }
    this.detailObject.invoiceDate = this.datePipe.transform(this.invoiceDate, 'yyyy-MM-dd');

    if (qtySupplied == 0 && partNumber.trim().length > 0) {
      this.emptyQtyValues = "Y";
    }
    if (qtySupplied != 0 && partNumber.trim().length === 0) {
      this.emptyValues = "P";
    }
    if (this.vendorCode.trim().length > 0) {
      this.detailObject.vendorCode = this.vendorCode.toUpperCase();
    }
    this.detailsList.push(this.detailObject);
    if (qtySupplied != 0 || partNumber.trim().length != 0) {
      if (rowValue === this.nullValues) {
        this.finalRowValue = this.nullValues;
        this.nullValues = this.nullValues + 1;
      } else {
        this.emptyValues = "Y";
      }
    }
  }

  vendorCheck() {
    if (this.vendorCode.toUpperCase().trim().length > 0) {
      this.asnService.getVendorDetails(this.vendorCode.toUpperCase(), this.marketData.marketId).subscribe(response => {
        if (response != null) {
          if (response.vdrCd.length > 0) {
            if (response.vdrType == 1) {

              if (response.vdrShipCd >= 900) {
                this.localVendor = "Y";
              } else {
                this.localVendor = "N";
              }
              this.enableForm = true;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access Denied', id: 'pop-up-message', life: 5000 });
              this.vendorCode = "";
              this.enableForm = false;
            }
          }
        }
      })
    }
  }
  asnNumberValueCheck(event) {
    if (event.target.value <= 9999999) {
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Asn Number should not be more than 9999999', id: 'pop-up-message', life: 5000 });
      this.asnNumber = null;
    }
  }


  slipNumberCheck(event) {
    if (event.target.value <= 999999) {
      // if (this.localVendor === "N") {
      this.asnService.getSlipNumberDetails(event.target.value, this.vendorCode, this.marketData.marketId).subscribe(response => {
        if (response.length == 0) {

          this.asnService.getInhSlipNumberDetails(event.target.value, this.vendorCode, this.marketData.marketId).subscribe(response => {
            if (response.length == 0) {

            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Packing Slip Number Value(' + event.target.value + ') is already exists', life: 5000 });
              this.slipNumber = null;
            }
          })
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Packing Slip Number Value(' + event.target.value + ') is already exists', life: 5000 });
          this.slipNumber = null;
        }
      })
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Packing Slip Number should not be more than 999999', id: 'pop-up-message', life: 5000 });
      event.target.value = null;
    }
  }



  downloadTemplate() {
    this.asnService.getTemplateFile(this.cdsid.id, this.cdsid.isCovisint).subscribe(data => {
      if (data) {
        const file = new Blob([data], { type: 'application/csv' });
        const fileURL = URL.createObjectURL(file);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = "AsnTemplate_" + formatDate(this.today, 'dd-MM-yyyy HH:mm:ss', 'en-US') + ".csv";
        a.click();
        window.URL.revokeObjectURL(fileURL);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: ' Template File downloaded Successfully', id: 'pop-up-message', life: 5000 });
        this.saveTransactionTableHistory("Download");
      }
    })
  }

  quantitySuppliedValidation(event, i: number) {
    if (event.target.value == "") {
      event.target.value = 0;
    }
    if (event.target.value > 999999) {
      event.target.value = 0;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Quantity Shipped Value should not be greater than 999999', id: 'pop-up-message', life: 5000 });
    }
    this.setqtySuppliedValue(i, event.target.value);

  }

  cumQuantitySuppliedValidation(event, i: number) {
    if (event.target.value == "") {
      event.target.value = 0;
    }
    if (event.target.value > 9999999) {
      event.target.value = 0;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Cumulative Quantity Shipped Value should not be greater than 9999999', id: 'pop-up-message', life: 5000 });
    } this.setcummlativeQtyValue(i, event.target.value);
  }

  partNumberValueCheck(event, i: number) {
    this.partNumberMap.set("partNumber0" + i, false);
    this.setPartNumberValue(i, event.target.value);
    if (event.target.value.trim().length > 0) {
      this.partNumberCheck.push(event.target.value);
      this.partNumberCheck.push(this.marketData.gsdb);
      this.asnService.getPartNumberDetails(this.partNumberCheck, this.marketData.marketId).subscribe(response => {
        if (response.length === 0) {
          this.setPartNumberValue(i, " ");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: event.target.value + ' Unknown Part Number', id: 'pop-up-message', life: 5000 });
          event.target.value = "";
        } else {
          this.partNumberMap.set("partNumber0" + i, true);
          // this.PartNumberArray.push(new PartNumberArrayValue(i, event.target.value, true));
        }
      })
      this.partNumberCheck = [];
    }
  }

  partNumberValueCheckBeforeSave(partNumber: string, index: number) {
    if (partNumber.trim().length > 0) {
      this.partNumberCheck.push(partNumber);
      this.partNumberCheck.push(this.marketData.gsdb);
      this.asnService.getPartNumberDetails(this.partNumberCheck, this.marketData.marketId).subscribe(response => {
        if (response.length === 0) {
          this.setPartNumberValue(index, " ");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: partNumber + ' Unknown Part Number', id: 'pop-up-message', life: 5000 });
        } else {
          this.partNumberMap.set("partNumber0" + index, true);
        }
      })
      this.partNumberCheck = [];
    }

  }

  invoiceNumberCheck(event) {
	  let invLength;
	  invLength = this.invoiceNumber.length;
	  if (invLength > 7) {
		this.newInvNumber = this.invoiceNumber.substr(invLength-7, invLength).toUpperCase();
	  }else {
		  this.newInvNumber = this.invoiceNumber.toUpperCase();
	  }
	  if (this.localVendor === 'Y') {
        this.asnService.getIvoiceNumberDetails(this.newInvNumber.toUpperCase(), this.vendorCode, this.marketData.marketId).subscribe(response => {
          if (response.length > 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invoice Number (' + this.invoiceNumber.toUpperCase() + ') is already exists', life: 5000 });
            this.invoiceNumber = null;
			this.slipNumber = null;
          } else {
            this.asnService.getA893IvoiceNumberDetails(this.newInvNumber.toUpperCase(), this.vendorCode, this.marketData.marketId).subscribe(response => {
              if (response.length == 0) {
                this.asnService.getInhIvoiceNumberDetails(this.newInvNumber.toUpperCase(), this.vendorCode, this.marketData.marketId).subscribe(response => {
                  if (response.length == 0) {

                  } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invoice Number (' + this.invoiceNumber.toUpperCase() + ') is already exists', life: 5000 });
                    this.invoiceNumber = null;
					this.slipNumber = null;
                  }
                })
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invoice Number (' + this.invoiceNumber.toUpperCase() + ') is already exists', life: 5000 });
                this.invoiceNumber = null;
				this.slipNumber = null;
              }
            })
          }
        })
		  this.getAffiliatePackSlipNum();
      }else{
		  this.getLocalPackSlipNum();
		  this.newSlipNumberCheck();
	  }
  }

  fileErr() {
    let bulkerr = (document.getElementById('customFile')! as HTMLInputElement).files;
    document.getElementById('cancel-button').click();
    if (bulkerr.length > 0 && this.validFileName === 'N') {

      const fileInput = document.getElementById('customFile')! as HTMLInputElement
      this.asnService.getUploadedFileValue(fileInput.files[0], this.marketData.gsdb, this.marketData.marketId).subscribe(data => {
        if (data) {
          this.asnService.getFileResponseMessage(this.marketData.marketId).subscribe(response => {
            if (response) {

              const file = new Blob([data], { type: 'application/csv' });
              const fileURL = URL.createObjectURL(file);
              let a = document.createElement('a');
              document.body.appendChild(a);
              a.href = fileURL;
              a.download = "AsnLogResult_" + formatDate(new Date(), 'dd-MM-yyyy_HH:mm:ss', 'en-US') + "_BY_" + this.cdsid.id + ".csv";
              a.click();
              window.URL.revokeObjectURL(fileURL);
            }
            if (response === "Successfully Uploaded") {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: response, id: 'pop-up-message', life: 5000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: response, id: 'pop-up-message', life: 5000 });
            }
            this.saveTransactionTableHistory('Upload');
            this.clearAll();
          })
        }
      }, error => {

      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Upload the Valid Document', id: 'pop-up-message', life: 5000 });
    }
    this.asnFileUpload.reset();
  }


  onChange(evt) {
    this.fileName = evt;
    if (this.fileName.substring((this.fileName.length - 4), this.fileName.length) === ".csv") {
      this.validFileName = 'N';
    } else {
      this.validFileName = 'Y';
    }
  }

  getPendingPartNumbetToValidate(key: string) {
    var value = "";
    switch (key) {
      case "partNumber00":
        value = this.partNumber00;
        break;
      case "partNumber01":
        value = this.partNumber01;
        break;
      case "partNumber02":
        value = this.partNumber02;
        break;
      case "partNumber03":
        value = this.partNumber03;
        break;
      case "partNumber04":
        value = this.partNumber04;
        break;
      case "partNumber05":
        value = this.partNumber05;
        break;
      case "partNumber06":
        value = this.partNumber06;
        break;
      case "partNumber07":
        value = this.partNumber07;
        break;
      case "partNumber08":
        value = this.partNumber08;
        break;
      case "partNumber09":
        value = this.partNumber09;
        break;
    }
    return value;
  }

  actionSubmit() {
    if (this.invoiceNumber != null && this.invoiceNumber.length > 0 && this.slipNumber > 0 && this.invoiceDate != null) {
      var newPartNumber = "";
      var indexValue = "";
      let map1 = new Map(
        [...this.partNumberMap]
          .filter(([k, v]) => v === false)
      );
      for (let [key, value] of map1) {
        if (value === false) {

          newPartNumber = this.getPendingPartNumbetToValidate(key);
          indexValue = key;

        }
      }
      if (newPartNumber.trim().length > 0 && this.partNumberMap.get(indexValue) === false) {
        this.partNumberCheck.push(newPartNumber);
        this.partNumberCheck.push(this.marketData.gsdb);
        this.asnService.getPartNumberDetails(this.partNumberCheck, this.marketData.marketId).subscribe(response => {
          if (response.length === 0) {
            this.setPartNumberValue(Number(indexValue.substring(11, 12)), " ");
          } else {
            this.partNumberMap.set("partNumber0" + Number(indexValue.substring(11, 12)), true);
            this.onOkClick();
          }
        })
        this.partNumberCheck = [];
      } else {
        this.onOkClick();
      }
    } else {

      this.submitForm = true;
      this.submitFormDate = true;
    }
  }

  saveTransactionTableHistory(evnt: string) {
    this.transactionTblValue = new transactionHistoryTableModel();
    this.transactionTblValue.formName = "ASNAdvanceShipment";
    this.transactionTblValue.canvas = "ASN";
    this.transactionTblValue.cdsId = this.cdsid.id;
    if (this.cdsid.isCovisint) {
      this.transactionTblValue.userRole = "Covisi";
    } else {
      this.transactionTblValue.userRole = "Ford";
    }
    this.transactionTblValue.event = evnt;
    this.transactionTblValue.transDate = new Date();
    this.transactionTblValue.msgKey = null;
    this.asnService.setTransactionTableValue(this.transactionTblValue, this.marketData.marketId).subscribe(response => {

    })
  }
  cancelButton() {
    this.clearAll();
    this.saveTransactionTableHistory("Cancel");
  }

  clearAll() {
    this.invoiceDate = undefined;
    this.submitForm = false;
    this.asnNumber = 0;
    this.invoiceNumber = " ";
    this.slipNumber = null;
    this.nullValues = 1;
    this.partNumberCount = 0;
    this.emptyValues = "N";
    this.caseNumber = "";

    this.partNumber00 = "";
    this.cummlativeQty01 = 0;
    this.qtySupplied01 = 0;

    this.partNumber01 = "";
    this.cummlativeQty02 = 0;
    this.qtySupplied02 = 0;

    this.partNumber02 = "";
    this.cummlativeQty03 = 0;
    this.qtySupplied03 = 0;

    this.partNumber03 = "";
    this.cummlativeQty04 = 0;
    this.qtySupplied04 = 0;

    this.partNumber04 = "";
    this.cummlativeQty05 = 0;
    this.qtySupplied05 = 0;

    this.partNumber05 = "";
    this.cummlativeQty06 = 0;
    this.qtySupplied06 = 0;

    this.partNumber06 = "";
    this.cummlativeQty07 = 0;
    this.qtySupplied07 = 0;

    this.partNumber07 = "";
    this.cummlativeQty08 = 0;
    this.qtySupplied08 = 0;

    this.partNumber08 = "";
    this.cummlativeQty09 = 0;
    this.qtySupplied09 = 0;

    this.partNumber09 = "";
    this.cummlativeQty10 = 0;
    this.qtySupplied10 = 0;
    this.finalRowValue = 0;
    this.PartNumberArray = [];
    this.partNumberMap = new Map<string, boolean>();
    this.asnScreen.reset();
  }

  setcummlativeQtyValue(index: number, cummlativeQty: number) {
    if (index === 0) {
      this.cummlativeQty01 = cummlativeQty;
    }
    if (index === 1) {
      this.cummlativeQty02 = cummlativeQty;
    }
    if (index === 2) {
      this.cummlativeQty03 = cummlativeQty;
    }
    if (index === 3) {
      this.cummlativeQty04 = cummlativeQty;
    }
    if (index === 4) {
      this.cummlativeQty05 = cummlativeQty;
    }
    if (index === 5) {
      this.cummlativeQty06 = cummlativeQty;
    }
    if (index === 6) {
      this.cummlativeQty07 = cummlativeQty;
    }
    if (index === 7) {
      this.cummlativeQty08 = cummlativeQty;
    }
    if (index === 8) {
      this.cummlativeQty09 = cummlativeQty;
    }
    if (index === 9) {
      this.cummlativeQty10 = cummlativeQty;
    }
  }

  setPartNumberValue(index: number, partNumber: string) {
    if (index === 0) {
      this.partNumber00 = partNumber;
    }
    if (index === 1) {

      this.partNumber01 = partNumber;
    }
    if (index === 2) {
      this.partNumber02 = partNumber;
    }
    if (index === 3) {
      this.partNumber03 = partNumber;
    }
    if (index === 4) {
      this.partNumber04 = partNumber;
    }
    if (index === 5) {
      this.partNumber05 = partNumber;
    }
    if (index === 6) {
      this.partNumber06 = partNumber;
    }
    if (index === 7) {
      this.partNumber07 = partNumber;
    }
    if (index === 8) {
      this.partNumber08 = partNumber;
    }
    if (index === 9) {
      this.partNumber09 = partNumber;
    }
  }


  setqtySuppliedValue(index: number, qtySupplied: number) {
    if (index === 0) {
      this.qtySupplied01 = qtySupplied;
    }
    if (index === 1) {
      this.qtySupplied02 = qtySupplied;
    }
    if (index === 2) {
      this.qtySupplied03 = qtySupplied;
    }
    if (index === 3) {
      this.qtySupplied04 = qtySupplied;
    }
    if (index === 4) {
      this.qtySupplied05 = qtySupplied;
    }
    if (index === 5) {
      this.qtySupplied06 = qtySupplied;
    }
    if (index === 6) {
      this.qtySupplied07 = qtySupplied;
    }
    if (index === 7) {
      this.qtySupplied08 = qtySupplied;
    }
    if (index === 8) {
      this.qtySupplied09 = qtySupplied;
    }
    if (index === 9) {
      this.qtySupplied10 = qtySupplied;
    }
  }

  getAffiliatePackSlipNum(){
	  let newPackSlipNum : string = '';
	  let locInvNum = this.newInvNumber;
	  if (this.newInvNumber.length > 6) {
		  locInvNum = locInvNum.substr(locInvNum.length-6, locInvNum.length).toUpperCase();
	  }
	  locInvNum = locInvNum.replace(/\s+/g,'');
	  for (let i = 0; i < locInvNum.length; i++) {
		  if(this.packSlipMap.has(locInvNum.charAt(i) ) ){
			  newPackSlipNum = newPackSlipNum + this.packSlipMap.get( locInvNum.charAt(i) ) ;
		  }else{
			  newPackSlipNum = newPackSlipNum + locInvNum.charAt(i) ;
		  }
	  }
	  this.slipNumber = Number(newPackSlipNum);
	  // console.log('Affiliate slipNum..', this.slipNumber);
  }

  getLocalPackSlipNum(){
	  let newPackSlipNum = this.newInvNumber;
	  if(newPackSlipNum.length >6){
		  newPackSlipNum = newPackSlipNum.substr(newPackSlipNum.length-6, newPackSlipNum.length);
	  }
	  newPackSlipNum = newPackSlipNum.replace(/\s+/g,'');
	  this.slipNumber = Number(newPackSlipNum.replace(/[a-zA-Z]/g,'1'));
	  // console.log('Local slipNum..', this.slipNumber);
  }

 newSlipNumberCheck() {
		this.asnService.getSlipNumberDetails(this.slipNumber, this.vendorCode, this.marketData.marketId).subscribe(response => {
				if (response.length == 0) {
					this.asnService.getInhSlipNumberDetails(this.slipNumber, this.vendorCode, this.marketData.marketId).subscribe(response => {
						if (response.length == 0) {

						} else {
							this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Packing Slip Number Value(' + this.slipNumber + ') is already exists', life: 5000 });
							this.slipNumber = null;
							this.invoiceNumber = null;
						}
					})
				} else {
					this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Packing Slip Number Value(' + this.slipNumber + ') is already exists', life: 5000 });
					this.slipNumber = null;
					this.invoiceNumber = null;
				}
			})
	}
}
export class PartNumberArrayValue {
  index: number;
  partNumber: string;
  status: boolean;

  constructor(index, partNumber, status) {
    this.index = index;
    this.partNumber = partNumber;
    this.status = status;
  }
}
