export class MenuItem {
    static getMenuItems(): any {
        return [
            // {
            //     'key': 'Home',
            //     'routerLink': '/panda-supplier/home',
            //     'menuLabel': 'Home'
            // },
            {
                'key': 'AdminStack',
                'routerLink': '/supplier/admin-stack',
                'menuLabel': 'Manage Announcement',
                'index': 1
                // Admin Stack
            },
            {
                'key': 'P20PartsOrderStatus',
                'routerLink': '/supplier/part-order-status',
                'menuLabel': 'Schedule Details by Part',
                'index': 2
                // P20 Parts Order Status
            },
            {
                'key': 'L15VendorPartsStatus',
                'routerLink': '/supplier/vendor-part-status',
                'menuLabel': 'Vendor Parts Summary',
                'index': 3
                // L15 Vendor Parts Status
            },
            {
                'key': 'M15GeneralSightingInquiry',
                'routerLink': '/supplier/panda-trial-component',
                'menuLabel': 'In Transit Inquiry',
                'index': 4
                // M15 General Sighting Inquiry
            },
            {
                'key': 'A79VendorContactDetails',
                'routerLink': '/supplier/vendor',
                'menuLabel': 'Vendor Contact Details Inquiry',
                'index': 5
                // A79 Vendor Contact Details
            },
            {
                'key': 'ASNPostAdvanceShipment',
                'routerLink': '/supplier/advance-shipment-notification',
                'menuLabel': 'Advance Shipment Notification Entry',
                'index': 6
                // ASN Advance Shipment Notification
            }
        ];
    }
}
