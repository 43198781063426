export class AdminStackModel {
    admSak?: number;
    fromDate?: string;
    toDate?: string;
    comment?: string;
    userComment?: string;
    createdDate?: Date;
    market?: string;
    commentTitle?:string;
    lastUpdatedTimeStamp?:Date;
}