import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { Details } from "../advance-shipment-notification/model/details.model";
import { transactionHistoryTableModel } from "../advance-shipment-notification/model/transactionHistoryTableModel";

@Injectable({
  providedIn: 'root'
})
export class AsnService {

  token: string;

  constructor(private http: HttpClient) {
    this.token = 'Bearer ' + sessionStorage['gateway_access_token']
  }

  getVendorDetails(gsdb: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + "v1/asnScreen/asnVendorCheck/" + gsdb, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  getPartNumberDetails(partnumberarray: string[], marketId: string): Observable<any> {
    return this.http.post(environment.baseUrl + 'v1/asnScreen/asnpartNumberCheck', partnumberarray, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  getSlipNumberDetails(slipNumber: number, gsdb: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + "v1/asnScreen/asnslipNumberCheck/" + slipNumber + "/" + gsdb, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  getInhSlipNumberDetails(slipNumber: number, gsdb: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + "v1/asnScreen/asnInhSlipNumberCheck/" + slipNumber + "/" + gsdb, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  getIvoiceNumberDetails(invoiceNumber: string, vendorCode: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + "v1/asnScreen/asninvoiceNumber/" + invoiceNumber + "/" + vendorCode, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  getA893IvoiceNumberDetails(invoiceNumber: string, vendorCode: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + "v1/asnScreen/asnA893invoiceNumber/" + invoiceNumber + "/" + vendorCode, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  getInhIvoiceNumberDetails(invoiceNumber: string, vendorCode: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + "v1/asnScreen/asnInhInvoiceNumber/" + invoiceNumber + "/" + vendorCode, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }


  setDetailsValue(details: Details[], marketId: string): Observable<any> {
    return this.http.post(environment.baseUrl + 'v1/asnScreen/saveDetailsValues', details,
      { 
        headers: { 'Content-Type': 'application/json', 'market': marketId, 'Authorization':this.token,  responseType: 'text' }
      },
    );
  }

  setTransactionTableValue(transaction: transactionHistoryTableModel, marketId: string): Observable<any> {
    return this.http.post(environment.baseUrl + 'v1/asnScreen/saveTransactionValues', transaction,
      { 
        headers: { 'Content-Type': 'application/json', 'market': marketId,'Authorization':this.token, responseType: 'text' }
      }
    );
  }

  getTemplateFile(cdsId: string, isCovisint: boolean): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('cdsId', (cdsId));
    if (isCovisint) {
      formData.append('userRole', ("Covis"));
    } else {
      formData.append('userRole', ("Non-Co"));
    }
    return this.http.post(environment.baseUrl + "v1/asnScreen/downloadTemplateFile", formData, 
      { 
        headers: { 'Authorization':this.token }, responseType: 'blob' as 'json'
      }
    );

  }

  getUploadedFileValue(files: File, vendorCode: string, marketId: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('files', files);
    formData.append('detailsValues', vendorCode);
    return this.http.post(environment.baseUrl + "v1/asnScreen/uploadFile", formData, 
      { headers: { 'market': marketId,'Authorization':this.token},responseType: 'blob' as 'json'  }
    );
  }
 
  getFileResponseMessage(marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + "v1/asnScreen/getLogMessage" , 
      { 
        headers: {'market': marketId, 'Authorization':this.token}, responseType: 'text' 
      }
    );
  }
}
