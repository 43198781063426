import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminStackComponent } from './admin-stack/admin-stack.component';
import { HomeComponent } from './home/home.component';
import { IndexComponent } from './index/index.component';
import { PartOrderStatusComponent } from './part-order-status/part-order-status.component';
import { SupplierComponent } from './supplier.component';
import { VendorPartStatusComponent } from './vendor-part-status/vendor-part-status.component';
import { VendorComponent } from './vendor/vendor.component';
import { PandaScreenLayoutComponent } from './panda-trial-component/panda-screen-layout/panda-screen-layout.component';
import { AdvanceShipmentNotificationComponent } from './advance-shipment-notification/advance-shipment-notification.component';
import { SupplierAuthGuardService } from './supplier-oauth/supplier-auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: SupplierComponent,
    children: [
      { path: 'home', component: HomeComponent, canActivate: [SupplierAuthGuardService] },
      { path: 'part-order-status', component: PartOrderStatusComponent, canActivate: [SupplierAuthGuardService] },
      { path: 'admin-stack', component: AdminStackComponent, canActivate: [SupplierAuthGuardService] },
      { path: 'vendor-part-status', component: VendorPartStatusComponent, canActivate: [SupplierAuthGuardService] },
      { path: 'vendor', component: VendorComponent, canActivate: [SupplierAuthGuardService] },
      { path: 'panda-trial-component', component: PandaScreenLayoutComponent, canActivate: [SupplierAuthGuardService] },
      { path: 'advance-shipment-notification', component: AdvanceShipmentNotificationComponent, canActivate: [SupplierAuthGuardService] },
      { path: '', redirectTo: 'home', pathMatch: 'full' }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierRoutingModule { }
