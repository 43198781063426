export class MarketData {
    marketName: string;
    gsdb: string; 
    marketId: string;

    marketData(marketName: string, gsdb: string, marketId: string){
        this.gsdb= gsdb;
        this.marketName = marketName;
        this.marketId = marketId;
    }
}