import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { OauthTokenService } from './oauth-token.service';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/dealer/services/common.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SupplierService } from '../services/supplier.service';
@Component({
	selector: 'oauth-callback',
	templateUrl: './oauth-callback.component.html',
	styleUrls: ['./oauth-callback.component.scss']
})
export class OAuthCallbackComponent implements OnInit {
	constructor(private router: Router, private oauthService: OauthTokenService,
		private bpo: BreakpointObserver, private supplierService: SupplierService,
		private http: HttpClient, private commonService: CommonService) {
	}
	public static FNA_ENTITY_NAME_CODE = 'USA'
	public static supportedCountryCodes: string[] = ['USA', 'MEX'];
	public userAuthData: any = {};
	cdsid: { id: string, isCovisint: boolean } = {
		id: '',
		isCovisint: false
	};
	userData: { fsnId: string, name: string } = {
		name: '',
		fsnId: '',
	}
	ngOnInit() {
		const callbackResponse = (document.URL).split('#')[1];
		const responseParameters = (callbackResponse).split('&');
		const parameterMap: any = {};
		responseParameters.forEach(eachParam => {
			const key = eachParam.split('=')[0];
			const value = eachParam.split('=')[1];
			parameterMap[key] = value;
		});
		sessionStorage['gateway_access_token'] = parameterMap['access_token'];
		sessionStorage['dealerToken'] = 'Test';
		const tokenInfo = this.getTokenInfo(sessionStorage['gateway_access_token']);
		// if (environment.device == 'web') {
		// 	this.bpo.observe(['(max-width: 1000px)'])
		// 		.subscribe(result => {
		// 			if (result.matches) {
		// 				const redirectURL = 'terms-condition';
		// 				window.location.href = environment.hostUrl + redirectURL;
		// 			}
		// 		});
		// }

		if (tokenInfo.ACIGROUP === "Employee" || tokenInfo.ACIGROUP === "Supplier") {
			const tokenSplit: string[] = callbackResponse.split('&');
			tokenSplit.map(param => {
				const key = param.split('=')[0];
				const value = param.split('=')[1];
				if (key === 'id_token' && value) {
					const decoded = jwt_decode(value);
					const unique_name: string = decoded['unique_name'];
                    // Implemented MFA and formatting the Employee ID
					if(tokenInfo.ACIGROUP === "Employee" && unique_name.includes('@')){
						const emp_name = unique_name.replace(/^([^@]+)@([^\.]+)\.com$/i, (match, p1, p2) => {
							return `${p2.toUpperCase()}\\${p1.toUpperCase()}`
						});
						if (emp_name.includes('\\')) {
							this.getUserData(emp_name);
						}
					}
					else if (unique_name.includes('\\') && tokenInfo.ACIGROUP === "Employee") {
						this.getUserData(unique_name);
					} else {
						this.cdsid.id = unique_name;
						this.cdsid.isCovisint = true;
						this.loadFsnData("COVISINT", this.cdsid.id, this.cdsid.isCovisint)
					}
				}
			});
			sessionStorage.setItem('cdsid', JSON.stringify(this.cdsid));
		} else if (tokenInfo.ACIGROUP === "Dealer") {
			console.log('inside Dealer');
			const id = tokenInfo.sub;
			sessionStorage['dealerId'] = id;
			this.commonService.fetchRole(id).subscribe(res => {
				this.responseHandler(res);
			});
		}
	}

	getTokenInfo(token: string): any {
		return JSON.parse(atob(token.split('.')[1]));
	}

	responseHandler(response) {
		debugger
		const roleAccess = ['PANDA:PartSearch', 'PANDA:PartInquiry:WithoutPriceData', 'PANDA:PartInquiry:WithPriceData'];
		if (response !== null && response !== undefined && response.Permitted !== null && response.Permitted !== undefined) {
			response.Permitted.forEach(element => {
				const authorizedUrl = element.read.some((v) => {
					return roleAccess.indexOf(v) >= 0;
				});
				if (authorizedUrl) {
					const redirectURL = '/dealer/dashboard';
					window.location.href = environment.hostUrl + redirectURL;
				} else {
					const redirectURL = '/unauthorized';
					window.location.href = environment.hostUrl + redirectURL;
				}
			});
		} else {
			const redirectURL = '/unauthorized';
			window.location.href = environment.hostUrl + redirectURL;
		}
	}

loadOnRole(isCovisint: boolean) {
		let permitItems;
		let menukeys: any[];
		let isPermit: boolean;
		this.supplierService.fetchRole(this.userData.fsnId, sessionStorage['gateway_access_token']).subscribe(accessItems => {
			permitItems = accessItems['Permitted'];
			if (permitItems) {
				permitItems.forEach(item => {
					if (item['read']) {
						const permittedKeys = item['read'];
						menukeys = permittedKeys.map(key => {
							return key.split(':')[1];
						});
						sessionStorage.setItem('menukeys', JSON.stringify(menukeys));
						isPermit = true;
					}
				});
			} else {
				isPermit = false;
			}
			if (isCovisint) {
				if (isPermit) {
					sessionStorage['SupplierLogin']=true;
					this.router.navigate(['/supplier']);
				} else {
					this.router.navigate(['/supplier-unauthorized']);
				}
			} else {
				sessionStorage['LoginType'] = 'Employee';
				window.location.href = environment.hostUrl + 'index';
			}
		});
	}

	loadFsnData(usertype: string, userid: string, isCovisint: boolean) {
		this.supplierService.getUserData(usertype, userid, sessionStorage['gateway_access_token']).subscribe((response: any) => {
			if (response) {
				this.userData.fsnId = response.fsnId;
				this.userData.name = response.firstName + " " + response.lastName;
				sessionStorage.setItem('userData', JSON.stringify(this.userData));
				this.loadOnRole(isCovisint);
			}
		})
	}

	//Generic function for MFA and Active Directory
	getUserData(unique_name: string) {
		this.cdsid.id = unique_name.split('\\')[1];
		this.cdsid.isCovisint = false;
		this.loadFsnData("CDSID", this.cdsid.id, this.cdsid.isCovisint)
	}
}
