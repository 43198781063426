import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { SupplierRoutingModule } from './supplier-routing.module';
import { SupplierComponent } from './supplier.component';
import { HomeComponent } from './home/home.component';
import { IndexComponent } from './index/index.component';
import { AdminStackComponent } from './admin-stack/admin-stack.component';
import { PartOrderStatusComponent } from './part-order-status/part-order-status.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SupplierHeaderComponent } from './supplier-header/supplier-header.component';
import { SupplierFooterComponent } from './supplier-footer/supplier-footer.component';
import { SupplierNavComponent } from './supplier-nav/supplier-nav.component';
import { TooltipModule } from 'primeng/tooltip';
import { VendorComponent } from './vendor/vendor.component';
import { PandaScreenLayoutComponent } from './panda-trial-component/panda-screen-layout/panda-screen-layout.component';
import { VendorPartStatusComponent } from './vendor-part-status/vendor-part-status.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { DealerRoutingModule } from '../dealer/dealer-routing.module';
import {ToastModule} from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import {SliderModule} from 'primeng/slider';
import {ContextMenuModule} from 'primeng/contextmenu';
import {ProgressBarModule} from 'primeng/progressbar';
import { SpacePipe } from './pipes/space.pipe';
import { AdvanceShipmentNotificationComponent } from './advance-shipment-notification/advance-shipment-notification.component';
import { PaginatorModule } from 'primeng/paginator';
@NgModule({
  declarations: [
    SupplierComponent,
    HomeComponent,
    IndexComponent,
    AdminStackComponent,
    PartOrderStatusComponent,
    SidebarComponent,
    SupplierHeaderComponent,
    SupplierFooterComponent,
    SupplierNavComponent,
    VendorPartStatusComponent,
    VendorComponent,
    PandaScreenLayoutComponent,
    AdvanceShipmentNotificationComponent,
    SpacePipe
  ],
  imports: [
    CommonModule,
    ToastModule,
    SupplierRoutingModule,
    TableModule,
    CheckboxModule,
    OverlayPanelModule,
    MultiSelectModule,
    DealerRoutingModule,
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    CalendarModule,
    MatSidenavModule,
    MatExpansionModule,
    SelectButtonModule,
    CardModule,
    InputSwitchModule,
    MatMenuModule,
    TabViewModule,
    DropdownModule,
    MatSlideToggleModule,
    ButtonModule,
    MenubarModule,
    PanelMenuModule,
    SidebarModule,
    PanelModule,
    InputTextModule,
    FormsModule,
    CheckboxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    DropdownModule,
    ConfirmDialogModule,
    DialogModule,
    ReactiveFormsModule,
    InputSwitchModule,
    TableModule,
    HttpClientModule,
    InputMaskModule,
    InputNumberModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    TooltipModule,
    FormsModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
		SliderModule,
		ContextMenuModule,
    ProgressBarModule,
    PaginatorModule,
    ProgressSpinnerModule
  ],
  providers: [CommonService,
    ConfirmationService, DatePipe, MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SupplierModule { }
