import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PartComment } from '../model/PartComment';
import { L15Comments } from '../vendor-part-status/models/l15comments.model';

@Injectable({
  providedIn: 'root'
})
export class L15Service {

  token: string;

  constructor(private http: HttpClient) {
    this.token = 'Bearer ' + sessionStorage['gateway_access_token']
  }

  getL15DetailsData(gsdb: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + 'v1/l15Details/' + gsdb, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  createComment(l15Comments: PartComment, marketId: string): Observable<any> {
    return this.http.post<any>(environment.baseUrl + 'v1/saveL15Comments', l15Comments, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

  getL15Comments(refNo: string, gsdb: string, marketId: string): Observable<any> {
    return this.http.get(environment.baseUrl + 'v1/getl15comments/' + refNo + '/' + gsdb, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market': marketId } });
  }

}
