import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/dealer/services/common.service';
import { MenuItem } from '../model/menu-Item';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  sideBar = false;

  menuContents: any;

  menuKeys: any;
  selectedMenu: number;

  constructor(private readonly commonService: CommonService) {
    this.menuContents = MenuItem.getMenuItems();
  }

  ngOnInit(): void {
    this.loadSideBarMenu();
  }

  loadSideBarMenu() {
    this.commonService.fetchRole(sessionStorage['cdsid']).subscribe(accessItems => {
      if (accessItems['Permitted']) {
        const permitItems = accessItems['Permitted'];
        permitItems.forEach(item => {
          if (item['read']) {
            const permittedMenuKey = item['read'];
            this.menuKeys = permittedMenuKey.map(key => {
              const menuKey = key.split(':')[1];
              return menuKey;
            });
          }
        });
        this.menuContents = this.menuContents.map(menu => {
          if (this.menuKeys.includes(menu['key'])) {
            return menu;
          }
        });
      }
    });
  }

  onToggle() {
    this.sideBar = !this.sideBar;
  }

  setActiveMenuItem(index: number) {
    this.selectedMenu = index;
    }

}
