import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';

import { DealerRoutingModule } from './dealer-routing.module';
import { DealerComponent } from './dealer.component';
import { PartsInquiryComponent } from './parts-inquiry/parts-inquiry.component';
import { BackOrderStatusComponent } from './back-order-status/back-order-status.component';
import { MatTabsModule } from '@angular/material/tabs';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {TooltipModule} from 'primeng/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule}from '@angular/material/table';
import {MatSortModule}from '@angular/material/sort';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule, } from 'primeng/card';

import { DropdownModule } from 'primeng/dropdown';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';

import { MatMenuModule } from '@angular/material/menu'
import { TabViewModule } from 'primeng/tabview';

import { MatExpansionModule } from '@angular/material/expansion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import {TableModule} from 'primeng/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { PartSearchComponent } from './part-search/part-search.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
// import {MultiSelectModule} from 'primeng/multiselect';
import {MultiSelectModule} from 'primeng/multiselect';
// import {MultiSelectModule} from 'primeng-lts/multiselect';
import { CommonService } from '../services/common.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ConfirmationService } from 'primeng/api/confirmationservice';

import { SupercessionInquiryComponent } from './supercession-inquiry/supercession-inquiry.component';
import { InputRestrictionDirective } from './inputVal.directive';
import { HasRoleDirective } from '../oauth-callback/hasRole.directive';
import { DashobardComponent } from './Dashobard/Dashobard.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { HttpRequestInterceptor } from './services/http-request-interceptor';
import { MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

@NgModule({
  declarations: [
    DealerComponent,
    DashobardComponent,
    PartsInquiryComponent,
    BackOrderStatusComponent,
    SupercessionInquiryComponent,
    PartSearchComponent,
    InputRestrictionDirective,
    HasRoleDirective,
    TermsConditionComponent
  ],
  imports: [
    CommonModule,
    ToastModule,
    MultiSelectModule,
    DealerRoutingModule,
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    MenuModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    CalendarModule,
    MatSidenavModule,
    MatExpansionModule,
    SelectButtonModule,
    CardModule,
    InputSwitchModule,
    MatMenuModule,
    TabViewModule,
    DropdownModule,
    MatSlideToggleModule,
    ButtonModule,
    MenubarModule,
    PanelMenuModule,
    SidebarModule,
    PanelModule,
    InputTextModule,
    FormsModule,
    CheckboxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    DropdownModule,
    ConfirmDialogModule,
    DialogModule,
    ReactiveFormsModule,
    InputSwitchModule,
    TableModule,
    HttpClientModule,
    InputMaskModule,
    InputNumberModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    TooltipModule,
    ProgressSpinnerModule
  ],
  providers: [MessageService,
    CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
      }
],
schemas: [
  CUSTOM_ELEMENTS_SCHEMA
]
})
export class DealerModule { }
