import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AdminStackService } from '../services/admin-stack.service';

@Component({
  selector: 'index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  markets: SelectItem[];
  city: string;
  marketValue: string;
  adminModelView: AdminStackService;
    gsdbs: SelectItem[];
    gsdb: string;

  constructor(private adminStackService: AdminStackService, private router: Router) { }

    ngOnInit() {
         this.markets = [
            { value: "India Chennai", label: "Ind-Chn" },
            { value: "India Delhi", label: "Ind-Del" },
            { value: "India Mumbai", label: "Ind-Mum" },
            { value: "India Kolkata", label: "Ind-Kol" },
            { value: "India Sanand", label: "India Sanand" },
            { value: "Thailand", label: "Thailand" },
        ];
        this.gsdbs = [
            { value: "1055K" ,label:  "1055K"},
            { value: "1055W" ,label:  "1055W"},
            { value: "0160A" ,label:  "0160A"},

        ];
        if (this.adminStackService.marketValue != null) {
            this.marketValue = this.adminStackService.marketValue;
            this.adminStackService.getHomeWithDateMarket(this.adminStackService.marketValue).subscribe(response => {
                this.adminModelView = response
            })
        }
    }
}
