export const qa = {
	hostUrl: 'https://fbmsng-qa.apps.pp01i.edc1.cf.ford.com',
    webClientId: 'urn:fbmsng:clientid:web_fbmsng:qa',
    webResourceId: 'urn:fbmsng:resource:web_fbmsng:qa',
    gatwayUrl: 'https://wwwqa-fbms.apps.pp01i.edc1.cf.ford.com',
    resourceId: 'urn:fbmsng:resource:api_fbms_developers:qa',
    clientId: '2c87efe7-f5e9-e75f-33e2-e0d864c06d89',
    clientSecret: '87kZj6BKdWXsHtgGiOki3fzIfqzSB-JNJ-XylVhQ',
    accessTokenUrl: 'https://corpqa.sts.ford.com'
};

export const dev = {
    hostUrl: 'https://localhost:8100',
	webClientId: 'urn:panda_poc:clientid:web_panda_mobile_app:dev',
	webResourceId: 'urn:panda_poc:resource:web_panda_mobile_app:dev',
	gatwayUrl: 'https://panda-responsive-supplier.apps.pp01i.edc1.cf.ford.com',
	resourceId: 'urn:fbmsng:resource:api_fbms_developers:dev',
	clientId: 'f19acfe4-00df-fd2a-8ec3-0dfa3bb0aec6',
	clientSecret: 'Ex83mQD8Zk5_4rmDn7CYTmsz6Nmdw76RaOJdlbNO',
	accessTokenUrl: 'https://corpqa.sts.ford.com'

	// hostUrl: 'https://fbmsngpocdev.apps.pd01i.edc2.cf.ford.com',
	// webClientId: 'urn:fbmsng:clientid:web_fbmsng:dev',
	// webResourceId: 'urn:fbmsng:resource:web_fbmsng:dev',
	//gatwayUrl: 'https://fbmsng-gateway.apps.pd01i.edc2.cf.ford.com',
	// resourceId: 'urn:fbmsng:resource:api_api_gateway:dev',
	// clientId: 'f89493c5-e6e7-209b-1611-c629f3f45c94',
	// clientSecret: 'SqivlBA1JsEd6NX083ApnpkZbjSGLNnKWAcPHFh0',
	// accessTokenUrl: 'https://corpqa.sts.ford.com'
};

export class ServerDetails {
	getDevEnvironment(): any {
		return dev;
	}
}
