import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private http: HttpClient) {
  }

  fetchRole(userId: string, token: string) {
    return this.http.get(environment.baseUrl + 'v1/supplier/mdpdecision/' + userId, { headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token } });
  }

  getUserData(userType: string, userId: string,token: string) {    
    return this.http.get(environment.baseUrl + 'v1/supplier/' + userType + '/' + userId, { headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token }});
  }

  fetchAttributes(userId: string, token: string) {
    return this.http.get(environment.baseUrl + 'v1/supplier/dlsattribute/' + userId, { headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' +token } });
  }

}
