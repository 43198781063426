import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { MarketData } from '../../model/marketData';
import { DesignService } from '../../services/design.service';
import { PandaTrialService } from '../../services/panda-trial-service';
import { PartOrderStatusService } from '../../services/part-order-status.service';
import { ItemDetails } from '../models/ItemDetails.model';
import { PartNumberDataModel } from '../models/PartNumberDataModel';

@Component({
  selector: 'app-panda-screen-layout',
  templateUrl: './panda-screen-layout.component.html',
  styleUrls: ['./panda-screen-layout.component.scss'],
  providers: [MessageService]
})
export class PandaScreenLayoutComponent implements OnInit {

  itemDetails: ItemDetails[] = [];
  partNumber: string;
  description: string;
  engineeringPartNumber:string;
  partName: string = '';
  backOrderPhaseQuantity: string
  onOrder: string
  backOrderPhaseQuantity2: string;
  onHand: string
  status: string;
  refNumber: number;
  tableColumns: any;
  orders = [];
  onActivities = [];
  disabled: boolean = true;
  showOverlaySpinner: boolean;
  showOverlay: boolean;
  partNumberDatas: PartNumberDataModel[] = null;
  partNumberDatasTemp: PartNumberDataModel[] = null;
  vendorCodeValue: string;
  isSpinner: any = 0;

  // Login Data
  marketData: MarketData;

  // Table Pagination
  first: number = 0;
  rows: number = 5;
  nextPart: string;
  @ViewChild('op', { static: false }) overlayPanel;
  @ViewChild('paginatortable', { static: false }) paginatortable: Paginator;
  @ViewChild('paginatortablePartData', { static: false }) paginatortablePartData: Paginator;


  constructor(private pandaTrialService: PandaTrialService, private router: Router,
    private activatedRoute: ActivatedRoute, private messageService: MessageService, private designService: DesignService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.refNumber = params['refNumber'];
      this.partNumber = params['partNumber'];
      this.partName = params['partName'];
      this.engineeringPartNumber=params['engineeringPartNumber'];
      this.status = params['status'];
    });
    this.marketData = JSON.parse(sessionStorage.getItem("market-data"));
    this.vendorCodeValue = this.marketData.gsdb;
    this.pandaTrialService.getPartNumberPanelDetails(this.vendorCodeValue, this.marketData.marketId).subscribe((response: any[]) => {
      if (response.length > 0) {
        this.showOverlaySpinner = true;
        this.showOverlay = false;
        this.partNumberDatas = response;
        this.partNumberDatasTemp=response;
        this.showOverlaySpinner = false;
        this.showOverlay = true;
        this.isSpinner = 1; // To stop spinner
      }else{
        this.isSpinner = 1;
      }
    })

    this.orders = [
      { part_number: "Order Bank Quantity", description: "" },
    ];
    this.onActivities = [
      { part_number: "On Order", description: "" },
    ];
    if (this.refNumber && this.partName && this.partNumber && this.status) {
      this.getItemDetails();
    }
  }

  checkPart(){
    // To ensure spinner stops and message is displayed
    if(this.partNumberDatas==null && this.isSpinner!=0){
      this.isSpinner = 1;
      this.overlayPanel.hide();
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Part Details Unavailable for Supplier Code: ' + this.marketData.gsdb, id: 'pop-up-message', life: 8000 });
    }
    // this.partNumberDatas=this.partNumberDatasTemp;
  }

  // Search Fucntionality for PartNumber
  checkKey(str: string){
    if(str){
      this.partNumberDatas = this.partNumberDatasTemp;
      this.partNumberDatas = this.partNumberDatas.filter(data => {
        // return data.gpfNpno.replace(/[\s]+/g,'').toLowerCase().includes(str.replace(/[\s]+/g,'').toLowerCase()) || data.hvyEpNo.replace(/[\s]+/g,'').toLowerCase().includes(str.replace(/[\s]+/g,'').toLowerCase());
        return data.gpfNpno.replace(/[\s]+/g,'').toLowerCase().includes(str.replace(/[\s]+/g,'').toLowerCase()) || data.hvyEpNo.replace(/[\s]+/g,'').toLowerCase().includes(str.replace(/[\s]+/g,'').toLowerCase());
      })
    }else{
      this.partNumberDatas = this.partNumberDatasTemp;
    }
    this.paginatortablePartData.first = 0;
  }

  // Table Pagination
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.itemDetails ? this.first === (this.itemDetails.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.itemDetails ? this.first === 0 : true;
  }

  onShow()
  {


    this.partNumberDatas=this.partNumberDatasTemp;
    }

  onRowSelect(event) {
    this.partNumber = event.data.gpfNpno.trim()
    this.partName = event.data.descNounAndSfx.trim()
    this.status = event.data.invStatusValues.trim()
    this.refNumber = event.data.gpfRefNo
    this.engineeringPartNumber=event.data.hvyEpNo;
    this.partNumberDatas = this.partNumberDatasTemp;
    this.getItemDetails();
    this.overlayPanel.hide();
  }

  getItemDetails() {
    this.pandaTrialService.getItemDetailsValue(this.marketData.gsdb, this.refNumber, this.marketData.marketId).subscribe(response => {
      if (response['erfTableList'].length != 0) {
        this.itemDetails = response['erfTableList'];
        this.orders[0].description = response['bankQty'];
        this.onActivities[0].description = response['onOrderQty'];
        this.paginatortable.first = 0;
      } else {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: '800065 - No Sightings data present'});
          this.clearAll();
          this.itemDetails = [];
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No Sightings data present', id: 'pop-up-message', life: 5000 });
      }
    })
  }

  showSuccess() {
    if (this.refNumber) {
      this.designService.setFastPath(2);
      this.router.navigateByUrl('/part-order-status', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/supplier/part-order-status'],
          { queryParams: { refNumber: this.refNumber, partNumber: this.partNumber,engineeringPartNumber:this.engineeringPartNumber, status: this.status, partName: this.partName } });
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Kindly select the Part Number', id: 'pop-up-message', life: 5000 });
    }
  }

  redirectToHome() {
    this.designService.setFastPath(0);
    this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/supplier/home']);
    });
  }

  clearAll() {
    this.itemDetails = [];
    this.orders[0].description = "";
    this.onActivities[0].description = "";
  }
}
