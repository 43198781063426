import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { L15Service } from '../services/l15service';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { VendorPart } from './models/vendorpart.model';
import { L15Comments } from './models/l15comments.model';
import { MarketData } from '../model/marketData';
import { PartComment } from '../model/PartComment';
import {Paginator} from 'primeng/paginator';
import {CsvDataService} from '../services/csvDataService';

@Component({
  selector: 'vendor-part-status',
  templateUrl: './vendor-part-status.component.html',
  styleUrls: ['./vendor-part-status.component.scss']
})
export class VendorPartStatusComponent implements OnInit {

  // Vendor Part Table Data
  vendorDetails: VendorPart[];
  csvDataDetails: VendorPart[];
  vendorDetailsResponseData: VendorPart[];
  commentDetails: PartComment[];
  partNumber: string;
  refNo: string;
  vendorCode: string;
  comment: string;
  currMonthSwitch: boolean = false;
  nextMonthSwitch: boolean = false;
  behndShdSwitch: boolean = false;
  nextFlag: boolean = false;
  currentFlag: boolean = false;
  bhndFlag: boolean = false;
  @ViewChild('paginatortable', { static: false }) paginatortable: Paginator;

  // L15 comments
  isComment: boolean;
  isAddComment: boolean;
  isCommentAdded: boolean = false;
  isCommentData: boolean = false;
  @ViewChild('partComment') partComment: NgForm;
  productData: VendorPart;
  isSpinner: any = 0;
  // Table pagination
	first: number = 0;
	rows: number = 5;

  // Login Data
  cdsid: { id : string, isCovisint: boolean} = {
		id: '',
		isCovisint: false
	};
  marketData: MarketData;
  newcsvData;
  newcsvDataTemp;
  latestDate;

	constructor(private l15Service: L15Service,
    private messageService: MessageService, private datepipe: DatePipe) { }


  ngOnInit(): void {
    this.marketData = JSON.parse(sessionStorage.getItem("market-data"));
    this.cdsid = JSON.parse(sessionStorage.getItem("cdsid"));
    this.getL15DetailsData();
  }
  getL15DetailsData() {
    this.l15Service.getL15DetailsData(this.marketData.gsdb, this.marketData.marketId).subscribe(response => {
      if (response && response.length != 0) {
        this.vendorDetails = response;
		this.csvDataDetails = response;
		this.vendorDetailsResponseData = response;
		this.isSpinner = 1; // To stop spinner
      } else {
		this.isSpinner = 1;
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No current schedule for this vendor', id: 'pop-up-message', life: 5000 });
      }
    })
  }

  addComment(product: VendorPart){
    this.partNumber = product.partNumber;
    this.refNo = product.gpfRefNumber;
    this.vendorCode = this.marketData.gsdb;
  }

  displayComments(product: VendorPart){
    this.commentDetails = null;
    this.productData = product;
    this.partNumber = product.partNumber;
    this.refNo = product.gpfRefNumber;
    this.vendorCode = this.marketData.gsdb;
    this.l15Service.getL15Comments(product.gpfRefNumber, this.marketData.gsdb,this.marketData.marketId).subscribe((response: PartComment[])=>{
      if(response && response.length!=0){
        this.isComment= false;
        response.sort((a,b) => +new Date(b.supCreatedTimestamp) - +new Date(a.supCreatedTimestamp));
        response.map(data=> {
          let newTimezone = new Date(data.supCreatedTimestamp);
          newTimezone.setMinutes(newTimezone.getMinutes()-newTimezone.getTimezoneOffset());
          data.supCreatedTimestamp = newTimezone;
        });
        this.commentDetails=response;
      }else{
        this.isComment= true;
      }
    })
  }

  submitComment() {
    let l15comment: PartComment = {};
    if(this.partComment.form.status === "VALID"){
      l15comment.supComments = this.comment.trim();
      if(l15comment.supComments !== ''){
        this.isCommentData = false;
        if(this.cdsid.isCovisint){
          l15comment.supUserType = "Supplier";
        }else{
          l15comment.supUserType = "Ford";
        }
        l15comment.supCreatedBy = this.cdsid.id;
        l15comment.supCreatedTimestamp = new Date();
        l15comment.supVdrCd = this.marketData.gsdb;
        l15comment.supRefNo = Number(this.refNo);
        l15comment.supLastUpdatedTimestamp = new Date();
        l15comment.supScreenFlg="L15";
        this.l15Service.createComment(l15comment, this.marketData.marketId).subscribe(response=>{
          if(response){
            this.isCommentAdded = true;
            this.l15Service.getL15Comments(this.refNo, this.marketData.gsdb,this.marketData.marketId).subscribe((response: PartComment[])=>{
              if(response && response.length!=0){
                this.isComment= false;
                response.sort((a,b) => +new Date(b.supCreatedTimestamp) - +new Date(a.supCreatedTimestamp));
                response.map(data=> {
                  let newTimezone = new Date(data.supCreatedTimestamp);
                  newTimezone.setMinutes(newTimezone.getMinutes()-newTimezone.getTimezoneOffset());
                  data.supCreatedTimestamp = newTimezone;
                });
                this.commentDetails=response;
              }else{
                this.isComment= true;
              }
            })
            this.comment = null;
            this.partComment.reset();
            setTimeout(() => {
              this.isCommentAdded = false;
              this.closeComment();
              this.closeModal();
            }, 2000);
          }
        })
      }else{
        this.isCommentData = true;
      }
    }else{
      this.isCommentData = true;
    }
  }

  activateComment(){
    this.isAddComment = true;
  }

  closeComment(){
    this.partComment.reset();
    this.isCommentData = false;
    this.isAddComment=false;
  }

  closeModal(){
    if(this.partComment){
      this.closeComment();
    }
    document.getElementById("close-comment-modal").click();
  }

	onCurrMonthSwitchChange(event) {
		this.currentFlag = event.checked;
		this.toggleVendorDetails();
	}

	onNextMonthSwitchChange(event) {
		this.nextFlag = event.checked;
		this.toggleVendorDetails();
	}

	onBehndShdSwitchChange(event){
		this.bhndFlag = event.checked;
		this.toggleVendorDetails();
	}

	toggleVendorDetails(){
		this.vendorDetails = this.vendorDetailsResponseData;

		if ( this.bhndFlag && !this.currentFlag && !this.nextFlag ){
			this.vendorDetails = this.vendorDetails.filter((data) => data.bhndSchd);
		}

		if ( ( this.currentFlag && !this.nextFlag && !this.bhndFlag )
		|| 	 ( this.currentFlag && this.bhndFlag && !this.nextFlag  ) ){
			this.vendorDetails = this.vendorDetails.filter((data) => data.currMonth || data.bhndSchd );
		}

		if ( ( this.nextFlag && !this.currentFlag && !this.bhndFlag )
		||   ( this.nextFlag && this.currentFlag  && !this.bhndFlag )
		||   ( this.nextFlag && this.bhndFlag && !this.currentFlag  )
		||	 ( this.nextFlag && this.currentFlag && this.bhndFlag	) ){
			this.vendorDetails = this.vendorDetails.filter((data) => data.nextMonth || data.currMonth || data.bhndSchd );
		}
		this.paginatortable.first = 0;
	}

	downloadData(){
		if(this.vendorDetails) {
			this.csvDataDetails = this.vendorDetails;
			this.newcsvData = this.csvDataDetails.map(details => {
				if (details.gpfRefNumber) {
					this.newcsvDataTemp = {
						'partNumber': details.partNumber,
						'epNo': details.epNo,
						'partDesc':details.partDesc,
						'bhndSchd': details.bhndSchd,
						'boLowIndex':details.boLowIndex,
						'currMonth':details.currMonth,
						'nextMonth': details.nextMonth,
						'rcptDate':details.rcptDate,
						'rcptQty': details.rcptQty,
						'packSlip': details.packSlip,
						'inProcess':details.inProcess
					}
					return this.newcsvDataTemp;
				}
				return this.newcsvDataTemp;
			});
			this.latestDate = this.datepipe.transform(new Date(), 'ddMMMyy')
				+ '_' + this.datepipe.transform(new Date(), 'HHmmss');
			if(this.newcsvData.length) {
				CsvDataService.exportToCsv('Vendor Parts Summary_'+this.latestDate+'.csv', this.newcsvData);
			}else {
				this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No data available for this vendor', id: 'pop-up-message', life: 5000 });
			}
		}else{
				this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No data available for this vendor', id: 'pop-up-message', life: 5000 });
			}
	}

	// Table Pagination methods
	next() {
		this.first = this.first + this.rows;
	}

	prev() {
		this.first = this.first - this.rows;
	}

	reset() {
		this.first = 0;
	}

	isLastPage(): boolean {
		return this.vendorDetails ? this.first === (this.vendorDetails.length - this.rows) : true;
	}

	isFirstPage(): boolean {
		return this.vendorDetails ? this.first === 0 : true;
	}
}
