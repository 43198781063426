import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackOrderStatusComponent } from './back-order-status/back-order-status.component';
import { DealerComponent } from './dealer.component';
import { PartSearchComponent } from './part-search/part-search.component';
import { PartsInquiryComponent } from './parts-inquiry/parts-inquiry.component';
import { CommonLayoutComponent } from '../common-layout/common-layout.component';
import { AuthGuardService } from '../oauth-callback/auth-guard.service';
import { SupercessionInquiryComponent } from './supercession-inquiry/supercession-inquiry.component';
import { DashobardComponent } from './Dashobard/Dashobard.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';

const routes: Routes = [
  {
    path: '',
    component:DealerComponent,
    children:[
      { path: 'dashboard', component: DashobardComponent,canActivate: [AuthGuardService]},
      { path: 'dealer-backorder/:partNumber', component: BackOrderStatusComponent, canActivate: [AuthGuardService] },
      { path: 'dealer-backorder', component: BackOrderStatusComponent, canActivate: [AuthGuardService] },
      { path: 'parts-enquiry/:partNumber', component: PartsInquiryComponent, canActivate: [AuthGuardService] },
      { path: 'parts-enquiry', component: PartsInquiryComponent, canActivate: [AuthGuardService] },
      { path: 'dealer-supersession/:partNumber', component: SupercessionInquiryComponent, canActivate: [AuthGuardService] },
      { path: 'dealer-supersession', component: SupercessionInquiryComponent, canActivate: [AuthGuardService] },
      { path: 'dealer-part-search', component: PartSearchComponent, canActivate: [AuthGuardService] },
      { path: 'terms-condition', component: TermsConditionComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
      // {path:'',component:DashobardComponent, canActivate: [AuthGuardService]},
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DealerRoutingModule {

}
