import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { AdminStackModel } from '../admin-stack/model/AdminStackModel';
import { Announcement } from '../model/announcement';
import { MarketData } from '../model/marketData';
import { AdminStackService } from '../services/admin-stack.service';
import { PrimeNGConfig } from 'primeng/api';
@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

    adminModelView: AdminStackModel[] = [];
    marketData: MarketData;

    constructor(private adminStackService: AdminStackService, private activatedRoute: ActivatedRoute,
        private datePipe: DatePipe, private primengConfig: PrimeNGConfig) { 
    }

    ngOnInit() {
        this.marketData = JSON.parse(sessionStorage.getItem("market-data"));
        this.primengConfig.ripple = true;
        this.adminStackService.getHomeWithDateMarket(this.marketData.marketName).subscribe((response: AdminStackModel[]) => {
            this.adminModelView = response;
            
        })
    }

}

