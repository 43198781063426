import { ChangeDetectorRef, Component, OnInit, NgZone } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';
import { CommonService } from '../services/common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute } from '@angular/router';
//import { App as CapacitorApp} from '@capacitor/app';
import { formatDate } from '@angular/common';

@Component({
  selector: 'part-search',
  templateUrl: './part-search.component.html',
  styleUrls: ['./part-search.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PartSearchComponent implements OnInit {
  modelCode;
  showmobFilter: boolean = true;
  partNumberSearch: FormGroup;
  loading: boolean;
  selectedModel: string[];
  partList: any[] = [];
  submitted = false;
  tableVal: any[] = [];
  showError: boolean = false;
  minLenCheck: boolean = false;
  totalRecords = 0;
  items: MenuItem[];
  rowData:any;
  //loading: boolean;
  first = 0;
  rows = 5;
  checked: boolean;
  datatableRequest;
  marked = false;
  backActive: boolean = false;
  accepted: boolean = true;
  rowsPerPageOptions = 5;
  dealerChanged = false;
  showFilter: boolean = false;
  showIcon: boolean;
  accountTableValues: any[];
  exportColumns: any[];
  model:any[];
  showDate: boolean;
  utcDate: string;
  constructor(private fb: FormBuilder,
    private dealerService: CommonService,
    public activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private router: Router, private cdRef: ChangeDetectorRef, private navigation: NavigationService) {

  }

  ngOnInit(): void {


    this.initialiseDealerOrderInquiryForm();
    this.getModelList();
    this.activatedRoute.params.subscribe((params) => {
      if (params.partNumber) {
        const part = params.partNumber.replace(/\s/g, '');
        if (part !== '') {
          this.partNumberSearch.controls['partNumber'].setValue(params.partNumber);
          this.searchDealer();
        }
      }
    });
    console.log('partno :', this.dealerService.partNumberFilter);
    if (this.dealerService.partNumberFilter && Object.keys(this.dealerService.partNumberFilter).length > 0) {
      this.restoreFilter();
    }else{
      this.showFilter = true;
    }
    // let country=sessionStorage.getItem('country');
    // if(country=="ARE")
    // {
      this.getDateTime();
      // this.showDate=true;
  
    // }
  //  else
  //  {
  //   this.showDate=false;  
  //  }
  }
  getDateTime()
  {
    const today = new Date();
    this.utcDate=formatDate(today, 'dd/MM/yyyy hh:mm a', 'en-US', 'UTC+4');
   
    console.log("utcdate",this.utcDate);
  }
  

  getModelList() {
    let country=sessionStorage.getItem('country');
    console.log(country);
    this.dealerService.fetchModelCodes().subscribe(res => {
      this.modelCode = res;
    });
  }
  // getModelList() {
  //   let country=sessionStorage.getItem('country');
  //   console.log(country);
  //   this.dealerService.fetchModelCodes(country).subscribe(res => {
  //     this.modelCode = res;
  //   });
  // }


  initialiseDealerOrderInquiryForm() {
    this.partNumberSearch = this.fb.group({
      partNumber: ['', Validators.minLength(3)],
      description: ['', Validators.minLength(3)],
      model: [[]],
      inexBackorder: [],
      inexObsolete: []
    });


    this.items = [{
      items: [
        {
          label: 'Backorder Inquiry', 
          icon: 'pi pi-book', 
          command: (event) => {
          this.navigation.isBackActive = true;
          this.navigation.setUrl('dealer/dealer-part-search');
          this.dealerService.partNumberFilter =this.partNumberSearch.getRawValue();
          // this.dealerService.filteredType=this.filteredType;
          const partno = this.rowData.partNumber.replace(/\s/g, '');
          this.router.navigate(['dealer-backorder/' + partno]);
        }
      },
          {
            label: 'Supersession Inquiry', 
            icon: 'pi pi-check-circle',
            
            command: (event) => {
              this.navigation.isBackActive = true;
              this.navigation.setUrl('dealer/dealer-part-search');
              this.dealerService.partNumberFilter =this.partNumberSearch.getRawValue();
              // this.dealerService.filteredType=this.filteredType;
              const partno = this.rowData.partNumber.replace(/\s/g, '');
              this.router.navigate(['dealer-supersession/' + partno]);
            }
             
            },
          
          
      ]}
    ];
  }
  selectedrow(rowData)
  {
    this.rowData=rowData;
  }
    
  searchDealer() {
    this.loading = true;
    this.showmobFilter = true;
    if (this.partNumberSearch.value.partNumber || this.partNumberSearch.value.description) {
      this.showError = false;
      if (this.partNumberSearch.valid) {
        this.searchPartDetail(0, 'partList.partNumber', 0);
      }
    }
    else {
      this.showError = true;
      this.showFilter = true;
      this.showmobFilter = false;
    }
  }

  searchPartDetail(startRecordNo: number, sortField: string, sortOrder: number) {
    console.table( this.partList);

    console.log('sortfield :', sortField);
  	this.loading = true;
    let backorder = 'N';
    if (this.partNumberSearch.value.inexBackorder) {
      backorder = 'Y';
      sortField = 'partList.bo';
      sortOrder = 0;
    }
    let obs = 'N';
    if (this.partNumberSearch.value.inexObsolete) {
      obs = 'Y';
    }
    const part = this.partNumberSearch.value.partNumber.replace(/\s/g, '');
    if(this.partNumberSearch.value.model === null) {
      this.model = [];
      }
      else{
      this.model = this.partNumberSearch.value.model;
      }
    this.datatableRequest = {
      filters: {
        partNumber: part,
        description: this.partNumberSearch.value.description,
        obsolete: obs,
        backOrder: backorder
      },
      // modelList: this.partNumberSearch.value.model,
      modelList: this.model,
      startingRecordNumber:startRecordNo,
      sortField: sortField,
      sortOrder: sortOrder
    };
    this.getTableData();

  }

  getTableData() {

    this.showError = false;
    let country=sessionStorage.getItem('country');
    this.dealerService.fetchPartSearchList(this.datatableRequest).subscribe(data => {
      this.responseHandler(data);
      this.submitted = true;
      this.loading = false;
      this.dealerService.partNumberFilter = [];
    });
  }

  responseHandler(response) {

    this.partList = response;
    console.table( this.partList);
    let country=sessionStorage.getItem('country');
    this.dealerService.fetchPartSearchCount(this.datatableRequest).subscribe(data => {
      this.getCount(data);
    });
    // this.partList=[];
    this.rowsPerPageOptions = 5;
    
    this.loading = false;
  }

  restoreFilter() {

    this.datatableRequest = {
      filters: {
        partNumber: this.dealerService.partNumberFilter?.partNumber.replace(/\s/g, ''),
        description: this.dealerService.partNumberFilter?.description.replace(/\s/g, ''),
        obsolete: this.dealerService.partNumberFilter.inexObsolete ? 'Y' : 'N',
        backOrder: this.dealerService.partNumberFilter.inexBackorder ? 'Y' : 'N',
      },
      modelList: this.partNumberSearch.value.model,
      startingRecordNumber: 0,
      sortField: 'partNumber',
      sortOrder: 1
    };
    this.partNumberSearch.get('partNumber').setValue(this.dealerService.partNumberFilter?.partNumber);
    this.partNumberSearch.get('description').setValue(this.dealerService.partNumberFilter?.description);
    this.partNumberSearch.get('model').setValue(this.dealerService.partNumberFilter?.model);
    this.partNumberSearch.get('inexBackorder').setValue(this.dealerService.partNumberFilter?.inexBackorder);
    this.partNumberSearch.get('inexObsolete').setValue(this.dealerService.partNumberFilter?.inexObsolete);
    this.getTableData();
  }
  removeSpace() {
    let part = this.partNumberSearch.value.partNumber.replace(/\s/g, '').toUpperCase();
    let partnumval= part.replace(/[^a-zA-Z 0-9]+/g,"");
    let descVal = this.partNumberSearch.value.description.replace(/\s/g, '').toUpperCase();
 
    this.partNumberSearch.controls['partNumber'].setValue(partnumval);
    this.partNumberSearch.controls['description'].setValue(descVal);
  }
  navToPartInq(rowData) {
    this.navigation.isBackActive = true;
    const partno = rowData.partNumber.replace(/\s/g, '');
    console.log('PARTNUMBERRRRRRR ' + partno)
    this.dealerService.partNumberFilter = this.partNumberSearch.getRawValue();

  
      this.navigation.setUrl('dealer/dealer-part-search');
      this.router.navigate(['parts-enquiry/' + partno]);

    

  }
  

  

  loadOrderDetail(event: LazyLoadEvent) {
    console.log(event);
    this.loading = true;
	let order = 1;
    if (event.sortOrder < 0) {
      order = 0;
    }
    let startNo = event.first;
    console.log("from event startno"+startNo);
    const sortField = event.sortField != null ? event.sortField : 'partList.partNumber'
    this.searchPartDetail(startNo, sortField, order);
  }

  getCount(response) {
    this.totalRecords = response;
    if(this.totalRecords==0){
      this.showFilter = true;
    } else {
      this.showFilter = false;
    }
  }
  showFilterOpt() {
    this.showFilter = true;
    this.showmobFilter = false;
  }

  clear() {
    //  this.initialiseDealerOrderInquiryForm();
    this.showError = false;
    this.minLenCheck = false;
    this.submitted = false;
    this.partNumberSearch.get('partNumber').setValue('');
    this.partNumberSearch.get('description').setValue('');
    this.partNumberSearch.get('model').reset();
    this.partNumberSearch.get('inexBackorder').reset();
    this.partNumberSearch.get('inexObsolete').reset();
    // this.partNumberSearch.reset();
    this.partList = [];
    this.totalRecords = 0;

  }
}
