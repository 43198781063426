import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'supplier-footer',
  templateUrl: './supplier-footer.component.html',
  styleUrls: ['./supplier-footer.component.scss']
})
export class SupplierFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
