import { CommonService } from 'src/app/dealer/services/common.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { OauthTokenService } from './oauth-token.service';
@Directive({ selector: '[hasRole]' })
export class HasRoleDirective {
  hasPermission;
  userData: string[];
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  private oauthService: OauthTokenService,
  private CommonService:CommonService,
  ) { }

  @Input() set hasRole(role: string[]) {
    console.log('*** Role Access ***')
    console.log(JSON.stringify(sessionStorage['roles']));
    this.userData = sessionStorage['roles'];
    // this.oauthService.fetchRole(sessionStorage['dealerId']).subscribe(res => {
      this.CommonService.fetchRole(sessionStorage['dealerId']).subscribe(res => {
      console.log('*******Fetch Role Access ********');
      this.responseHandler(res, role);
    });
  }

  responseHandler(response, role: string[]) {
    if (response !== null && response.Permitted !== null) {
      response.Permitted.forEach(element => {
        this.hasPermission = element.read.some((v) => {
          return role.indexOf(v) >= 0;
        });
        if (this.hasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    }
  }
}

