
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { OrderFilter } from '../dealer-order-inquiry/orderfilter';
/**
 *
 * Admin
 * @export
 * @class AdminService
 */
@Injectable()
export class CommonService {
  // orderFilter: OrderFilter = new OrderFilter();
  orderUrl: string;
  partUrl: string;
constructor(public http: HttpClient) {
    this.orderUrl = 'http://localhost:8081/';
    this.partUrl = 'http://localhost:8080/';
  }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  // getIsBackActive() {
  //   return this.isBackActive;
  // }
  getAllRoles() {
    return this.http.get(`http://localhost:8081/api/v1/hello`);
  }

  fetchDealerCodes() {
    return this.http.get(this.orderUrl + `api/dealer-inquiry/dealerCode`);
  }

  fetchDealerOrderList(dataTableRequest) {
    return this.http.post(this.orderUrl + `api/dealer-inquiry/order-list`, dataTableRequest, this.httpOptions);
  }

  fetchHeaderDetail(orderInquiry) {
    return this.http.post(this.orderUrl + `api/dealer-inquiry/header-detail`, orderInquiry, this.httpOptions);
  }

  fetchPartOrderList(dataTableRequest) {
    return this.http.post(this.orderUrl + `api/dealer-inquiry/part-list`, dataTableRequest, this.httpOptions);
  }
  fetchLineDetail(dataTableRequest) {
    return this.http.post(this.orderUrl + `api/dealer-inquiry/line-detail`, dataTableRequest, this.httpOptions);
  }
  fetchDealerDetail(orderDetail) {
    return this.http.post(this.orderUrl + `api/order-entry/dealer-detail`, orderDetail, this.httpOptions);
  }
  fetchPartDetail(partNumber) {
    return this.http.get(this.partUrl + `api/v2/inquiry/part/` + partNumber);
  }
  fetchMTDSales(dealerCode) {
    return this.http.get(this.orderUrl + `api/dealer-inquiry/mtd-sales/` + dealerCode);
  }
  saveOrder(orderDetail) {
    return this.http.post(this.orderUrl + `api/order-entry/save`, orderDetail, this.httpOptions);
  }
}
