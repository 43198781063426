import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DesignService {

  sidebarDesign: {selectedItem: number, isfastPath: boolean} = {
    selectedItem: 0,
    isfastPath: false
  };

  constructor() { }

  setFastPath(index: number){
    this.sidebarDesign.selectedItem = index;
    this.sidebarDesign.isfastPath = true;
  }

  getItemNo(){
    return this.sidebarDesign;
  }

  customreset(){
    this.sidebarDesign.selectedItem = 0;
    this.sidebarDesign.isfastPath = false;
  }


}
