export class Market {
    marketId: string;
    marketName: string;
    timeZone: string;
    // marketHeader: string;

    market(marketId: string, marketName: string){
        // , timeZone: string
        this.marketName= marketName;
        this.marketId = marketId;
        // this.timeZone = timeZone;
    }
}
