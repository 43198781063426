import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {

  agreeTerms: boolean = false;
  enableDownload:boolean = false;
  constructor() { }
  ngOnInit(): void {
  }

  agreeCondition() {
    this.agreeTerms = true;
  }
  back() {
    this.agreeTerms = false;
  }

 downloadApk(){
    let link = document.createElement("a");
    link.download = "panda";
    link.href = "https://raw.github.ford.com/PandA-FCSD/panda-android-apk/master/PANDA_QA.apk?token=AAAILCDHLOPJUAH6OXJ5CRLCC7BAK";

    link.click();
  }

  downloadipa(){
    let link = document.createElement("a");
    link.download = "panda";
    link.href = "https://raw.github.ford.com/PandA-FCSD/panda-android-apk/master/PANDA.ipa?token=AAAILCA7DW7OY77PNEDCGULCC7BDS";
    link.click();
  }

}
