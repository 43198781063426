import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Announcement } from '../model/announcement';
import { Market } from '../model/market';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  token: string;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'market':'pandacommon'})
  }

  marketValue: string ;
  constructor(private http: HttpClient) {
    this.token = 'Bearer '+ sessionStorage['gateway_access_token'];
  }

  setMarketData(response: any[]){
    let marketData: Market[]= [];
    for (const iterator of response) {
        marketData.push(this.dataSet(iterator));
    }
    return marketData;
  }

  dataSet(iterator){
    let market: Market = new Market();
    switch(iterator){
      case "FI1AA-Australia": market.market('AU',"FI1AA-Australia");break;
      case "2589A-China Chongqing CN":
      case "2589A-China Chongqing": market.market('CN',"2589A-China Chongqing");break;
      case "EHSTA-China Hangzhou CN2":
      case "EHSTA-China Hangzhou": market.market('CN2',"EHSTA-China Hangzhou");break;
      case "EHSTB-China Beijing CN3":
      case "EHSTB-China Beijing": market.market('CN3',"EHSTB-China Beijing");break;
      case "EHSTC-China Guangzhou CN4":
      case "EHSTC-China Guangzhou": market.market('CN4',"EHSTC-China Guangzhou");break;
      case "EHSTD-China Zhengzhou CN5":
      case "EHSTD-China Zhengzhou": market.market('CN5',"EHSTD-China Zhengzhou");break;
      case "EHSTE-China Wuhan CN6":
      case "EHSTE-China Wuhan": market.market('CN6',"EHSTE-China Wuhan");break;
      case "EHSTF-China Harbin CN7":
      case "EHSTF-China Harbin": market.market('CN7',"EHSTF-China Harbin");break;
      case "0411B-Ford China Operations": market.market('FCO',"0411B-Ford China Operations");break;
      case "1055K-India Chennai IN":
      case "1055K-India Chennai": market.market('IN1',"1055K-India Chennai");break;
      case "FKARA-India Delhi IN2":
      case "FKARA-India Delhi": market.market('IN2',"FKARA-India Delhi");break;
      case "GMF5A-India Mumbai IN3":
      case "GMF5A-India Mumbai": market.market('IN3',"GMF5A-India Mumbai");break;
      case "GRQGA-India Kolkata IN4":
      case "GRQGA-India Kolkata": market.market('IN4',"GRQGA-India Kolkata");break;
      case "1055W-India Sanand IN5":
      case "1055W-India Sanand": market.market('IN5',"1055W-India Sanand");break;
      case "3859A-Middle East": market.market('ME',"3859A-Middle East");break;
      case "1774A-New Zealand": market.market('NZ',"1774A-New Zealand");break;
      case "2002A-Philippines": market.market('PH',"2002A-Philippines");break;
      case "2164A-South Africa": market.market('ZA',"2164A-South Africa");break;
      case "0647A-Taiwan": market.market('TW',"0647A-Taiwan");break;
      case "1155B-Thailand": market.market('THA',"1155B-Thailand");break;
      case "BGBGA-Vietnam Hanoi VN":
      case "BGBGA-Vietnam Hanoi": market.market('VN1',"BGBGA-Vietnam Hanoi");break;
      case "HNHYA-Vietnam HCMC VN2":
      case "HNHYA-Vietnam HCMC": market.market('VN2',"HNHYA-Vietnam HCMC");break;
	  case "SBOM": market.market('PANDACOMMON',"SBOM");break;
    }
    return market;
  }

  getGSDB(marketId: string, covisint: string): Observable<any>{
    return this.http.get<any>(environment.baseUrl + 'v1/supplier/fetchgsdbcodes',
    {headers: { 'Content-Type': 'application/json', 'market':marketId, 'Authorization':this.token},
    params: { 'covisint': covisint, 'market': marketId} });
  }

  getMarket(covisint: string): Observable<any>{
    return this.http.get<any>(environment.baseUrl + 'v1/supplier/getMarkets', {headers : { 'Content-Type': 'application/json', 'Authorization':this.token},
    params: { 'covisint': covisint}});
  }

  verifyVendor(marketId: string, gsdbCode: string): Observable<any>{
		return this.http.get(environment.baseUrl + 'v1/supplier/verifygsdbcode',
			{headers: { 'Content-Type': 'application/json', 'market':marketId, 'Authorization':this.token},
				params: { 'gsdbCode': gsdbCode, 'market': marketId} , responseType: 'text' });
	}

}
