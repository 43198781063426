import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PartComment } from '../model/PartComment';

@Injectable({
  providedIn: 'root'
})
export class PartOrderStatusService {
  
  token: string;

  constructor(private http:HttpClient) { 
    this.token = 'Bearer '+ sessionStorage['gateway_access_token']
  }

  getPartNumberDetails(gsdb: string, marketId: string):Observable<any>{
    return this.http.get(environment.baseUrl + 'v1/loadgsdbparts/'+gsdb, { headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market':marketId}});
  } 

  getDetailsPartData(partNumber:number, marketId: string, gsdb:string):Observable<any>{
    return this.http.get(environment.baseUrl + 'v1/partdetailsnew/'+partNumber+'/'+gsdb,{ headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market':marketId}});
  }

  createP20Comments(partComment: PartComment, marketId: string): Observable<any> {
    return this.http.post<any>(environment.baseUrl + 'v1/savep20comments', partComment, {headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market':marketId}});
  }

  getP20Comments(refNo: number,gsdb:string, delvDate: string ,marketId: string): Observable<any>{
    return this.http.get(environment.baseUrl + 'v1/getp20comments/'+refNo+'/'+gsdb+"/"+delvDate, {headers: { 'Content-Type': 'application/json','Authorization':this.token, 'market':marketId}});
  }
}
